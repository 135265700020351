import Frame from '@/frame/Index.vue';

import Copy from '@/views/course/setting/detail/courseware/Copy.vue';

const routes = [
  {
    path: '/frame',
    component: Frame,
    children: [
      {
        path: 'course/choose-course',
        component: Copy,
      },
    ],
  },
];

export default routes;

