import ActivityCenterNavs from '@/utils/Navs/ActivityCenterNavs';
import LearnMapNavs from '@/utils/Navs/LearnMapNavs';
import IndexConfig from '@/utils/Navs/IndexConfig';
import UserConfig from '@/utils/Navs/UserConfig';
import OnlineCommunity from '@/utils/Navs/OnlineCommunity';
import DataStatisticsNavs from '@/utils/Navs/DataStatisticsNavs';
import TrainingNavs from '@/utils/Navs/TrainingNavs';
import StudentFilesNavs from '@/utils/Navs/StudentFilesNavs';
import Preparation from './preparation';

const Navs = [
  { id: 1, name: '首页配置', prefixPath: '/index/entrance/', navs: IndexConfig },
  { id: 2, name: '活动中心', prefixPath: '/index/activities/', navs: ActivityCenterNavs },
  { id: 3, name: '学习地图', prefixPath: '/index/learnMap/', navs: LearnMapNavs },
  { id: 4, name: '等级配置', prefixPath: '/index/userConfig/', navs: UserConfig },
  { id: 5, name: '问答社区', prefixPath: '/index/onlineCommunity/', navs: OnlineCommunity },
  { id: 6, name: '直播流量统计', prefixPath: '/index/data-statistics/', navs: DataStatisticsNavs },
  { id: 7, name: '内训管理', prefixPath: '/index/training/', navs: TrainingNavs },
  { id: 8, name: '学员档案', prefixPath: '/index/studentFiles', navs: StudentFilesNavs },
  { id: 9, name: '筹建店', prefixPath: '/index/preparation', navs: Preparation },
];

function mergeMenu(menus, localMenus, onlyLocalMenus) {
  localMenus = localMenus || Navs;

  if (onlyLocalMenus) {
    return localMenus;
  }

  if (!menus?.length && !localMenus?.length) {
    return [];
  }
  menus = [...menus];
  localMenus.map(nav => {
    if (nav.isDev) {
      menus.push({ ...nav });
      return;
    }

    const idx = menus.findIndex(menu => menu.is_jump && isSameModule(menu.jump_url, nav.prefixPath));

    if (idx === -1) {
      return;
    }
    menus[idx].prefixPath = nav.prefixPath;
    menus[idx].navs = nav.navs;
  });

  return menus;
}

function isSameModule(url, localUrl) {
  if (url === localUrl) {
    return true;
  }
  const urlArr = url.split('/').filter((i) => i);
  const localUrlArr = localUrl.split('/').filter((i) => i);

  return urlArr[0] === localUrlArr[0] && urlArr[1] === localUrlArr[1];
}

export { mergeMenu };

export default Navs;

