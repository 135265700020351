<template>
  <a-form :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          class="full-page form">
    <a-form-item label=" " :colon="false">
      <a-button @click="addCourse()">+新增新课程</a-button>
    </a-form-item>
    <a-form-item v-for="(item, index) in formList"
                 :key="index"
                 label="选择课程课件">
      <app-select-base v-model="item.courseId"
                       mode="default"
                       :list="item.courseList"
                       :options="{id: 'id', name: 'title'}"
                       :allow-select-all="false"
                       :allow-clear="false"
                       :per-page="120"
                       :custom-search="true"
                       placeholder="请选择课程"
                       class="select-course select"
                       @search="getCourseList($event, item.id)"
                       @change="getCoursewareList($event, index)"/>

      <app-select-base v-model="item.coursewareIds"
                       :list="item.coursewareList"
                       :options="{id: 'id', name: 'title'}"
                       placeholder="请选择课件"
                       :allow-select-all="true"
                       :allow-clear="true"
                       class="select"/>

      <a-button v-if="index" @click="remove(item)">X</a-button>
    </a-form-item>

    <a-form-item label=" " :colon="false">
      <a-button :disabled="saving" type="primary" @click="onSave">保存</a-button>
      <a-button :disabled="saving" type="primary" @click="onBack">返回列表</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
// import axios from 'axios';

import {
  getCourseList,
  // getCoursewareList,
  saveCourseware,
} from '../../api/courseware.copy';

const defaultFormItem = {
  id: 1,
  courseId: '',
  coursewareIds: '',
  courseList: [],
  coursewareList: [],
};

export default {
  name: 'Copy',
  data() {
    return {
      fetching: false,
      saving: false,
      coursewareRequest: null,
      globalId: 1,

      courseList: [], // 新添加的行默认的课程列表

      courseId: this.$route.query.course_id,

      formList: [],
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  created() {
    this.addCourse();
    this.getCourseList('', 0, true);
  },
  methods: {
    onBack() {
      (top || window).history.back();
    },

    async getCourseList(title = '', id, isDefault) {
      this.fetching = true;
      const data = await getCourseList({
        page: 1,
        per_page: 100,
        title,
      }).finally(() => {
        this.fetching = false;
      });
      if (!data || data.error_code) {
        this.$message.error('获取课程列表失败');
        return;
      }
      if (isDefault) {
        this.courseList = data.data || [];
        this.formList.forEach((item) => {
          if (!item.courseList?.length) {
            item.courseList = [...this.courseList];
          }
        });
      }

      const formItem = this.formList.filter((i) => i.id === id)?.[0];
      if (formItem) {
        formItem.courseList = data.data || [];

        if (formItem.tempCourse?.id && formItem.courseList.every((i) => i.id !== formItem.tempCourse.id)) {
          formItem.courseList.unshift(formItem.tempCourse);
        }
      }
    },
    getCoursewareList(id, index) {
      const formItem = this.formList.filter((i, idx) => idx === index)[0] || {};
      // fix 搜索功能 bug，搜索课程并选中后，会触发一次 search，并且参数 title 为空字符串，导致选中课程的课件等丢失问题。
      // course 临时保存被选中课程对象，如果课程列表里没有，就把他 unshift 到列表第一个
      formItem.tempCourse = formItem.courseList.find((i) => +i.id === +id); // 被选中的课程对象
      formItem.coursewareIds = '';
      formItem.coursewareList = [
        ...(formItem.courseList.find((item) => +item.id === +id)?.children || []),
      ];
    },
    async onSave() {
      const ids = this.formList.map(
        (item) => item.coursewareIds,
      ).filter(
        (item) => !!item,
      ).join(',');

      if (!ids) {
        this.$message.info('请选择课程课件');
        return;
      }
      if (this.saving) {
        return;
      }
      this.saving = true;

      const params = {
        bind_course_id: this.courseId,
        task_ids: ids,
      };
      const data = await saveCourseware(params).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.onBack();
    },

    addCourse() {
      this.globalId += 1;
      this.formList.push({
        ...defaultFormItem,
        courseList: [...this.courseList],
        id: this.globalId,
      });
    },

    remove(item) {
      this.formList.splice(this.formList.indexOf(item), 1);
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  overflow-y: auto;
}

.select {
  width: calc(40% - 10px);
}

.select-course {
  margin-right: 10px;
}
</style>
