import request from '@/utils/Request';
import JSEncrypt from "jsencrypt";
const EncryptKey =
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvYJmZFVGyeciRa1K388vgb1OO76Qd+4TBiB4IBLtRJQD+Uu5YjrRRQHk1R00wPN/rAB9D0dsIi4RAwyGR91Jbqxrl2jz3l9kqVXik2I+DGtHp9B8B5sJkl0bsjLrckLGZieOLc2ohHi8z/GyEa5j0q+e+PfssVJJNm5crdYTI7HO70Pv0RALHatowhaYNkxdG3VDL3QY784Bay+y9OryyF5+huJa6K6BkjNebRSCyXS6Qi/X8gLTtdvN7njjzgqU5jNnOB9RO29uxrUfL4VXCYF2t5QkH9vRoue++w6CClV7HxuIKm758e0dBguWZxdEt73fqllgZ689AAd5Gc0h0QIDAQAB";
// 用户登录
export function login (data) {
  return request({
    url: "/noauth/loginJava",
    method: "POST",
    data
  });
}

// 验证身份证号是否存在
export function exists (data) {
  return request({
    url: '/noauth/idcard/exists',
    method: 'POST',
    data,
  });
}

// 发送用户激活验证码
export function smsActive (data) {
  return request({
    url: '/noauth/sms/active',
    method: 'POST',
    data,
  });
}


// 用户激活
export function activate (data) {
  return request({
    url: '/noauth/activate',
    method: 'POST',
    data,
  });
}

// 发送忘记密码验证码
export function smsForgetpassword (data) {
  return request({
    url: '/noauth/sms/forgetpassword',
    method: 'POST',
    data,
  });
}


// 找回密码
export function forgetpassword (data) {
  return request({
    url: '/noauth/forgetpassword',
    method: 'POST',
    data,
  });
}
// 获取验证码
export function getCloudList(params) {
  return request({
    url: `/eqlearn-openapi/eqlearn/Login/createCaptcha`,
    method: "get",
    params
  });
}
// // 用户登录
// export function login(data) {
//   return request({
//     url: "/eqlearn-openapi/eqlearn/Login/login",
//     method: "POST",
//     data
//   });
// }

// RSA 加密
export const jsEncrypt = (str) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(EncryptKey);
    return encrypt.encrypt(str);
};
