<template>
  <div class="ss-header-nav">
    <a-menu mode="horizontal" theme="dark"
            :overflowedIndicator="initIndicator"
            :multiple="false"
            v-model="selectedIds"
            class="menu"
            @select="selectMenu">
      <a-menu-item v-for="item of menus" :key="item.id">
        <span>{{ item.name }}</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import {mergeMenu} from '@/utils/Navs/Index';

export default {
  name: 'HeaderNav',
  computed: {
    ...mapState({
      menu: state => state.Common.menu,
    }),
    selectedId() {
      return this.selectedIds?.length ? this.selectedIds[0] : 0;
    },
  },
  watch: {
    '$route.path'() {
      this.init();
    },
    'menu'(menu) {
      this.menus = mergeMenu(menu, undefined, this.onlyLocalMenu);
      this.init();
    },
  },
  data() {
    return {
      selectedIds: [],
      menus: [],

      onlyLocalMenu: false,
    }
  },
  created() {
    if (this.menu.length) {
      this.menus = mergeMenu(this.menu, undefined, this.onlyLocalMenu);
      this.init();
    }
  },
  methods: {
    init() {
      const path = this.$route.path || '';
      const [nav] = this.menus.filter(nav => path.includes(nav.prefixPath));
      if (!nav?.id) {
        return this.selectedIds = [];
      }
      if (nav.id === this.selectedId) {
        return;
      }
      this.selectedIds = [nav.id];
    },
    selectItem(item) {
      if (!item?.id || this.selectedId === item.id) {
        return;
      }
      this.$emit('select', item);
    },

    initIndicator() {
      return (<div class="caret"><span>更多</span>
        <a-icon type="caret-down"/>
      </div>);
    },

    selectMenu({key}) {
      if (!key || this.selectedId === key) {
        return;
      }

      const [item] = this.menus.filter(i => i.id === key);

      if (!item.prefixPath) {
        this.$redirect(item.jump_url);
        return;
      }

      this.$emit('select', item);
    },
  },
}
</script>

<style scoped lang="scss">
.ss-header-nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .menu {
    width: 100%;
    height: 100%;
    background-color: $basic01;
  }

  .ant-menu-item {
    height: 100%;
    line-height: 60px;
    border-right: 1px solid $basic02 !important;

    &.ant-menu-item-selected {
      color: $light01;
      background-color: $basic02;
      cursor: default;
    }
  }

  .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: $basic03;
  }

  .caret {
    height: 60px;
    line-height: 60px;
    color: rgba(255, 255, 255, 0.65);
  }

  .ant-menu-submenu-open .caret {
    color: #FFFFFF;
  }
}
</style>
