import Activity from '@/router/modules/activityCenter/Activity';
import HappyNewYear from '@/router/modules/activityCenter/HappyNewYear'
const Index = () => import(/* webpackChunkName: "ActivityCenter" */  '@views/activityCenter/Index');

const scriptRouter = [
  {
    path: 'activities',
    component: Index,
    children: [
      { path: '', redirect: 'list' },
      ...Activity,
      ...HappyNewYear
    ],
  },
];

export default scriptRouter;
