import request from '../utils/Request';

// 公共-岗位信息
export function getJobs() {
  return request({
    url: '/manage/select/jobs',
    method: 'get',
  });
}

// 公共-获取全部岗位
export function getJobsGrouped() {
  return request({
    url: '/public/conf/getAllPost',
    method: 'get',
  });
}

// 公共-机构信息树形结构
export function getOrgTree() {
  return request({
    url: '/manage/select/orgtree',
    method: 'get',
  });
}

// 所有发布的课程
export function getCourses() {
  return request({
    url: '/public/conf/publishcourse',
    method: 'get',
  });
}

// 考试中心列表
export function getExams() {
  return request({
    url: '/public/conf/publishexam',
    method: 'get',
  });
}

// 获取举报类型
export function getReportType() {
  return request({
    url: '/commonno/select/informtype',
    method: 'get',
  });
}

// 学历
export function getDegreeList(params) {
  return request({
    url: `/public/select/degree`,
    method: 'get',
    params,
  });
}

// 后台--岗位--全部岗位(分等级)
export function getPostLevelList(params) {
  return request({
    url: `/manage/admin/select/leveljobs`,
    method: 'get',
    params,
  });
}

// 后台-根据用户角色返回机构信息树形结构
export function getOrgTreeByUser(params) {
  return request({
    url: `/manage/admin/select/orgtreeByUser`,
    method: 'get',
    params,
  });
}

// 汽车品牌的接口
export function getBrandList(params) {
  return request({
    url: `/public/select/carBrand`,
    method: 'get',
    params,
  });
}
