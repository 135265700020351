import request from '@/utils/frameRequest';

// 课程列表
export function getCourseList(params) {
  return request({
    url: '/course/admin/show-bind-task',
    method: 'get',
    params,
  });
}

// 课件列表
export function getCoursewareList(params, cancelToken) {
  return request({
    url: '/course/admin/show-bind-task',
    method: 'get',
    params,
    cancelToken,
  });
}

// 克隆课件
export function saveCourseware(data) {
  return request({
    url: '/course/admin/clone-task',
    method: 'post',
    data,
  });
}
