<template>
  <a-layout class="index-page">
    <a-layout-header class="clear-bg clear-pd">
      <Header>
        <HeaderNav slot="menu" @select="selectMenu"></HeaderNav>
      </Header>
    </a-layout-header>
    <a-layout>
      <a-layout-sider v-show="navShown" class="clear-bg">
        <Nav ref="navBar" :navs="navs" @select="selectNav"></Nav>
      </a-layout-sider>
      <a-layout-content class="layout-content">
        <Main></Main>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { getToken } from '@/utils/Token';

import Navs from '@/utils/Navs/Index';

import Header from '@/components/Header';
import HeaderNav from '@/components/HeaderNav';
import Nav from '@/components/Nav';
import Main from '@/components/Main';

export default {
  name: 'Index',
  components: {
    Header,
    HeaderNav,
    Nav,
    Main,
  },
  computed: {
    navs() {
      return this.activeMenu?.navs || [];
    },
    navShown() {
      return !this.$route.meta?.navHide;
    },
  },
  watch: {
    '$route.path'() {
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      activeMenu: {},
    };
  },
  created() {
    this.$store.dispatch('Common/getMenu');
    this.$store.dispatch('Common/getJobs');
    this.$store.dispatch('Common/getOrgTree');
    this.$store.dispatch('User/getLinks');

    const userToken = getToken();
    this.$store.commit('User/updateToken', userToken);
  },
  mounted() {
    this.init();
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    init() {
      const path = this.$route?.path || '';
      const [nav] = Navs.filter(nav => path.includes(nav.prefixPath));
      this.activeMenu = nav;
    },
    selectNav({ path }) {
      this.$router.push(path);
    },

    async selectMenu(menu) {
      if (!menu?.id || menu.id === this.activeMenu.id) {
        return;
      }
      this.loading = true;
      await this.$router.push(menu?.prefixPath);
      this.activeMenu = menu || {};
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.index-page {
  width: 100%;
  height: 100%;
}

.clear-bg {
  background: unset;
}

.clear-pd {
  padding: 0;
}

.layout-content {
  overflow: auto !important;
}
</style>
