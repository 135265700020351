const ActivityList = () => import(/* webpackChunkName: "ActivityCenter" */ '@views/activityCenter/List');
const ActivityDetail = () => import(/* webpackChunkName: "ActivityCenter" */ '@views/activityCenter/Detail');
const ActivitySequence = () => import(/* webpackChunkName: "ActivityCenter" */ '@views/activityCenter/Sequence');

const activityRouter = [
  {
    name: 'ActivityList',
    path: 'list',
    component: ActivityList,
  },
  {
    name: 'ActivityDetail',
    path: 'detail',
    component: ActivityDetail,
  },
  {
    name: 'ActivitySequence',
    path: 'sequence',
    component: ActivitySequence,
  },
];

export default activityRouter;
