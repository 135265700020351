import {getActivity} from '@/api/activityCenter/Index';

const ActivityCenter = {
  namespaced: true,
  state: {
    readOnly: true,
    activityId: 0,
    list: [],
    sequence: [],
    detail: {},
  },
  mutations: {
    updateReadOnly(state, readOnly) {
      state.readOnly = !!readOnly;
    },
    updateActivityId(state, id) {
      state.activityId = id || 0;
    },
    updateList(state, list) {
      state.list = list;
    },
    updateSequence(state, list) {
      state.sequence = list;
    },
    updateDetail(state, detail) {
      state.detail = detail;
    },
  },
  actions: {
    async getActivity({commit}, id) {
      if (!id) {
        commit('updateReadOnly', false);
        commit('updateActivityId', null);
        commit('updateDetail', {});
        return;
      }

      const data = await getActivity({id: id});
      if (!data || data.error_code) {
        return {};
      }
      const result = data?.data || {};
      commit('updateReadOnly', result.status === 1);
      commit('updateActivityId', id);
      commit('updateDetail', result);
    },
  },
};

export default ActivityCenter;
