import {
  getDegreeList,
  getPostLevelList,
  getOrgTreeByUser,
  getBrandList,
} from '@/api/Selection';

const store = {
  namespaced: true,
  state: {
    degreeList: [],
    degreeLoading: false,

    postLevelList: [],
    postLevelLoading: false,

    orgTreeByUser: [],
    orgTreeByUserLoading: false,

    brandList: [],
    brandLoading: false,
  },
  mutations: {
    updateDegreeList(state, { list, loading } = {}) {
      state.degreeList = list || [];
      state.degreeLoading = loading;
    },
    updatePostLevelList(state, { list, loading } = {}) {
      state.postLevelList = list || [];
      state.postLevelLoading = loading;
    },
    updateOrgTreeByUser(state, { list, loading } = {}) {
      state.orgTreeByUser = list || [];
      state.orgTreeByUserLoading = loading;
    },
    updateBrandList(state, { list, loading } = {}) {
      state.brandList = list || [];
      state.brandLoading = loading;
    },
  },
  actions: {
    async getDegreeList({ commit, state }, { force } = {}) {
      if ((state?.degreeList?.length || state?.degreeLoading) && !force) {
        return;
      }
      commit('updateDegreeList', { list: state.degreeList, loading: true });
      const data = await getDegreeList();
      commit('updateDegreeList', { list: data?.data?.degree || [], loading: false });
    },
    async getPostLevelList({ commit, state }, { force } = {}) {
      if ((state?.postLevelList?.length || state?.postLevelLoading) && !force) {
        return;
      }
      commit('updatePostLevelList', { list: state.postLevelList, loading: true });
      const data = await getPostLevelList();
      commit('updatePostLevelList', { list: data?.data || [], loading: false });
    },
    async getOrgTreeByUser({ commit, state }, { force } = {}) {
      if ((state?.orgTreeByUser?.length || state?.orgTreeByUserLoading) && !force) {
        return;
      }
      commit('updateOrgTreeByUser', { list: state.orgTreeByUser, loading: true });
      const data = await getOrgTreeByUser();
      commit('updateOrgTreeByUser', { list: data?.data || [], loading: false });
    },
    async getBrandList({ commit, state }, { force } = {}) {
      if ((state?.brandList?.length || state?.brandLoading) && !force) {
        return;
      }
      commit('updateBrandList', { list: state.brandList, loading: true });
      const data = await getBrandList();
      commit('updateBrandList', { list: data?.data || [], loading: false });
    },
  },
};

export default store;
