import {stringify} from 'qs';
import {baseUrl} from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import {downloadFileExtra} from '@/utils/JsDownload';


// 评论-评论列表
export function getCommentList(params) {
  return request({
    url: `/ask/admin/comment/index`,
    method: 'get',
    params,
  });
}

// 评论-评论列表-导出 excel
export function getCommentListDownload(params) {
  let url = `/ask/admin/comment/index`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 评论-删除评论
export function deleteComment(data) {
  return request({
    url: `/ask/admin/comment/delete`,
    method: 'post',
    data,
  });
}

// 评论-回复列表
export function getReplyList(params) {
  return request({
    url: `/ask/admin/comment/reply/index`,
    method: 'get',
    params,
  });
}

// 评论-回复列表-导出 excel
export function getReplyListDownload(params) {
  let url = `/ask/admin/comment/reply/index`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 评论-删除回复
export function deleteReply(data) {
  return request({
    url: `/ask/admin/comment/delete`,
    method: 'post',
    data,
  });
}
