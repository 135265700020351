import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';

// 内训---列表（统一+自主）
export function getUnifiedTrainingList (params) {
    return request({
        url: `/internaltraining/admin/conf/list`,
        method: 'get',
        params,
    });
}

// 内训---列表（统一+自主）-导出 excel
export function getUnifiedTrainingListDownload (params) {
    let url = `/internaltraining/admin/conf/list`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训---删除
export function deleteUnifiedTraining (id) {
    return request({
        url: `/internaltraining/admin/conf/${id}`,
        method: 'delete',
    });
}

// 内训---基础信息详情
export function getUnifiedTrainingBasic (id) {
    return request({
        url: `/internaltraining/admin/conf/base/${id}`,
        method: 'get',
    });
}

// 内训---添加/修改
export function createUnifiedTrainingBasic (data) {
    return request({
        url: `/internaltraining/admin/conf/base/add`,
        method: 'post',
        data,
    });
}

// 内训---内训岗位内容（第二页）
export function getUnifiedTrainingContent (id) {
    return request({
        url: `/internaltraining/admin/conf/content/${id}`,
        method: 'get',
    });
}

// 内训内容---内容信息详情
export function getUnifiedTrainingContentItem (id) {
    return request({
        url: `/internaltraining/admin/detail/content/${id}`,
        method: 'get',
    });
}

// 内训内容---删除内容详情
export function deleteUnifiedTrainingContentItem (id) {
    return request({
        url: `/internaltraining/admin/detail/delete/${id}`,
        method: 'delete',
    });
}

// 内训内容---统一内训添加
export function saveUnifiedTrainingContent (id, data) {
    return request({
        url: `/internaltraining/admin/conf/contentUnified/${id}`,
        method: 'post',
        data,
    });
}

// 内训---批量修改内训岗位关联
export function saveUnifiedTrainingPostPeopleCount (id, data) {
    return request({
        url: `/internaltraining/admin/conf/relpostbatch/${id}`,
        method: 'post',
        data,
    });
}

// 内训回传资料--获取表单项和回传类型
export function getUnifiedTrainingMaterialTypes () {
    return request({
        url: `/internaltraining/admin/conf/getInformConfig`,
        method: 'get',
    });
}

// 内训回传资料-获取
export function getUnifiedTrainingMaterial (id) {
    return request({
        url: `/internaltraining/admin/conf/inform/${id}`,
        method: 'get',
    });
}

// 内训回传资料-保存
export function saveUnifiedTrainingMaterial (id, data) {
    return request({
        url: `/internaltraining/admin/conf/inform/${id}`,
        method: 'post',
        data,
    });
}

// 内训---详细信息包含所有内容
export function getUnifiedTrainingConfirm (id) {
    return request({
        url: `/internaltraining/admin/conf/fullinfo/${id}`,
        method: 'get',
    });
}

// 内训----发布内训
export function publishUnifiedTraining (id, data) {
    return request({
        url: `/internaltraining/admin/conf/publish/${id}`,
        method: 'post',
        data,
    });
}

// 内训详情-经销商-基础信息
export function getUnifiedTrainingDetailBasic (id, params) {
    return request({
        url: `/internaltraining/admin/detail/base/${id}`,
        method: 'get',
        params,
    });
}

// 内训详情-经销商-基础信息-导出 excel
export function getUnifiedTrainingDetailBasicDownload (id, params) {
    let url = `/internaltraining/admin/detail/base/${id}`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训详情-经销商-基础信息-导出学习进度 excel
export function getUnifiedTrainingDetailBasicLearnProgressDownload (id, params) {
    let url = `/internaltraining/admin/getOrgsCourseCompleteDetail/${id}`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训详情-经销商-完成进度
export function getUnifiedTrainingDetailProgress (id, params) {
    return request({
        url: `/internaltraining/admin/detail/progress/${id}`,
        method: 'get',
        params,
    });
}

// 内训详情-经销商-完成进度-导出 excel
export function getUnifiedTrainingDetailProgressDownload (id, params) {
    let url = `/internaltraining/admin/detail/progress/${id}`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训详情-经销商-回传材料进度
export function getUnifiedTrainingDetailMaterial (id, params) {
    return request({
        url: `/internaltraining/admin/detail/upfileProgress/${id}`,
        method: 'get',
        params,
    });
}

// 内训详情-经销商-回传材料进度-导出 excel
export function getUnifiedTrainingDetailMaterialDownload (id, params) {
    let url = `/internaltraining/admin/detail/upfileProgress/${id}`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训详情-经销商-获取评分
export function getTrainingScores (id, params) {
    return request({
        url: `/internaltraining/admin/detail/getScore/${id}`,
        method: 'get',
        params,
    });
}

// 内训详情-经销商-设置内训评分
export function saveTrainingScore (id, data) {
    return request({
        url: `/internaltraining/admin/detail/setScore/${id}`,
        method: 'post',
        data,
    });
}

// 内训详情-经销商-评分表
export function getTrainingScoreList (id, params) {
    return request({
        url: `/internaltraining/admin/detail/score/${id}`,
        method: 'get',
        params,
    });
}

// 内训详情-经销商-评分-导出 excel
export function getTrainingScoreListDownload (id, params) {
    let url = `/internaltraining/admin/detail/score/${id}`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训列表--经销商列表--查看提交材料
export function getUnifiedTrainingDetailSubmittedMaterial (id, params) {
    return request({
        url: `/internaltraining/admin/offline/show/${id}`,
        method: 'get',
        params,
    });
}

// 内训列表--经销商列表--查看提交材料--打回
export function rejectUnifiedTrainingDetailSubmittedMaterial (id, data) {
    return request({
        url: `/internaltraining/admin/offline/refuse/${id}`,
        method: 'post',
        data,
    });
}

// 结算内训
export function saveSettlement (data) {
    return request({
        url: `/internaltraining/admin/settlement`,
        method: 'post',
        data,
    });
}

// 内训管理--内训列表--内训详情--评分查询（后台榜）
export function getScoreOrgList (params) {
    return request({
        url: `/internaltraining/admin/getOrgScoreList`,
        method: 'get',
        params,
    });
}

// 内训管理--内训列表--内训详情--评分查询（后台榜）-导出 excel
export function getScoreOrgListDownload (id, params) {
    let url = `/internaltraining/admin/getOrgScoreList`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训管理--内训列表--内训详情--当前门店所有内训评分列表
export function getScoreTrainingList (params) {
    return request({
        url: `/internaltraining/admin/getOrgScoreDetail`,
        method: 'get',
        params,
    });
}

// 内训管理--内训列表--内训详情--当前门店所有内训评分列表-导出 excel
export function getScoreTrainingListDownload (id, params) {
    let url = `/internaltraining/admin/getOrgScoreDetail`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训管理--内训列表--内训详情--门店内训评分明细
export function getScoreDetail (params) {
    return request({
        url: `/internaltraining/admin/getOrgTrainingScoreDetail`,
        method: 'get',
        params,
    });
}

// 内训管理--内训列表--内训详情--门店内训评分明细-导出 excel
export function getScoreDetailDownload (id, params) {
    let url = `/internaltraining/admin/getOrgTrainingScoreDetail`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}

// 内训列表--经销商列表--学员明细（统一 & 自建）
export function getOrgStudentDetailList (params) {
    return request({
        url: `/internaltraining/admin/Student/TrainingStatistics`,
        method: 'get',
        params,
    });
}

// 内训列表--经销商列表--学员明细（统一 & 自建）-导出 excel
export function getOrgStudentDetailListDownload (params) {
    let url = `/internaltraining/admin/Student/TrainingStatistics`;
    if (baseUrl[baseUrl.length - 1] === '/') {
        url = url.substring(1);
    }
    const path = `${baseUrl}${url}?${stringify(params)}`;
    return downloadFileExtra({
        url: path,
        method: 'GET',
        token: store.state.User.token || '',
    });
}





// 新增材料
export async function  postMaterialAdd (id, data) {
    return request({
        url: `/internaltraining/admin/conf/material/add/${id}`,
        method: 'post',
        data,
    });
}

// 获取材料列表
export function getMaterialShow (id, params) {
    return request({
        url: `/internaltraining/admin/conf/material/show/${id}`,
        method: 'get',
        params,
    });
}


// 移除材料
export function postMaterialDelete (data) {
    return request({
        url: `/internaltraining/admin/conf/material/remove`,
        method: 'post',
        data,
    });
}


