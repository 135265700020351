<template>
  <a-select v-model="selectedIds"
            ref="selectRef"
            :allow-clear="true"
            :mode="mode"
            :show-search="true"
            :get-popup-container="getContainer"
            :disabled="disabled"
            :filter-option="filterOption"
            placeholder="请选择岗位"
            class="select"
            @change="select"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
    <a-select-option v-if="mode === 'multiple'" :value="0" @click="selectAll">
      全选
    </a-select-option>
    <a-select-option v-for="item in ops" :key="item.id" :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectJob',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String },
    disabled: { type: Boolean, default: false },
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) =>
        ['multiple', 'default', 'tags', 'combobox'].includes(val),
    },
    list: { type: Array, default: () => [] },
    optionsIds: { default: () => [] },
  },
  computed: {
    ...mapState({
      options: (state) => state.Common.jobList,
    }),
    ops() {
      const list = this.list?.length ? this.list : this.options;
      if (this.optionsIds && this.optionsIds.length > 1) {
        return list.filter((s) => {
          return this.optionsIds.split(',').includes(s.id.toString());
        });
      }
      return this.list?.length ? this.list : this.options;
    },
    getContainer() {
      // 1. 下拉框跟随输入框滚动
      // 2. 当用于 form-model-item 时，两个 $parent 保证 mode === 'multiple' 时，选中选项后，保证下拉框不消失
      return () =>
        this.$refs.selectRef?.$parent?.$parent?.$el ||
        this.$root?.$el ||
        document.body ||
        null;
    },
  },
  watch: {
    value() {
      this.setDefault();
    },
    // optionsIds: {
    //   handler(value) {
    //     console.log('optionsIds', value, this.ops);
    //     this.ops = this.ops.filter((s) =>
    //       value?.split(',')?.includes(s.id.toString())
    //     );
    //     console.log('optionsIds', value, this.ops);
    //   }
    // }
  },
  data() {
    return {
      selectedIds: [],
      isAllClicked: false,
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    render() {
    },

    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) > -1;
    },

    selectAll() {
      this.isAllClicked = true;
    },

    setDefault() {
      if (!this.value) {
        this.ids = '';
        this.selectedIds = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      const ids = this.value.split(',').filter((i) => !!i);
      this.selectedIds = this.mode === 'multiple' ? ids.map((i) => +i) : ids[0];
      if (this.mode === 'default') {
        this.selectedIds = this.mode === 'multiple' ? ids.map((i) => +i) : +ids[0];
      }
    },

    select() {
      if (!this.ops || !this.ops.length) {
        return;
      }

      if (this.isAllClicked && this.mode === 'multiple') {
        this.selectedIds = this.ops.map((op) => op.id);
        this.selectedIds.unshift(0);
      }
      this.isAllClicked = false;

      const selectedIds =
        this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];

      const ids = selectedIds.filter((i) => !!i);
      this.$emit('select', ids);
      this.$emit('change', ids.join(','));
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 350px;
}
</style>
