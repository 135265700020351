import Vue from 'vue';
import * as Ant from 'ant-design-vue';

Vue.use(Ant.Avatar);
Vue.use(Ant.Badge);
Vue.use(Ant.Breadcrumb);
Vue.use(Ant.Button);
Vue.use(Ant.Card);
Vue.use(Ant.Carousel);
Vue.use(Ant.Cascader);
Vue.use(Ant.Checkbox);
Vue.use(Ant.Col);
Vue.use(Ant.Collapse);
Vue.use(Ant.ConfigProvider);
Vue.use(Ant.DatePicker);
Vue.use(Ant.Descriptions);
Vue.use(Ant.Divider);
Vue.use(Ant.Dropdown);
Vue.use(Ant.Empty);
Vue.use(Ant.Form);
Vue.use(Ant.FormModel);
Vue.use(Ant.Icon);
Vue.use(Ant.Input);
Vue.use(Ant.InputNumber);
Vue.use(Ant.Layout);
Vue.use(Ant.List);
Vue.use(Ant.LocaleProvider);
Vue.use(Ant.Menu);
Vue.use(Ant.Modal);
Vue.use(Ant.Pagination);
Vue.use(Ant.Radio);
Vue.use(Ant.Row);
Vue.use(Ant.Select);
Vue.use(Ant.Space);
Vue.use(Ant.Spin);
Vue.use(Ant.Switch);
Vue.use(Ant.Table);
Vue.use(Ant.Tag);
Vue.use(Ant.Tooltip);
Vue.use(Ant.Transfer);
Vue.use(Ant.Tree);
Vue.use(Ant.Upload);
Vue.use(Ant.Tag);

Vue.prototype.$message = Ant.message;
Vue.prototype.$confirm = (options) => {
  const ops = Object.assign({
    title: '提示',
    content: () => <div>确认操作？</div>,
    mask: true,
    maskClosable: true,
    okButtonProps: {
      props: { disabled: false, loading: false },
    },
  }, options);
  return Ant.Modal.confirm(ops);
};

Vue.prototype.$notice = (options) => {
  const op = Object.assign({
    title: '温馨提示',
    content: () => <div>确认操作？</div>,
    mask: true,
    maskClosable: true,
  }, options);
  return Ant.Modal.info(op);
};
