import Broadcast from '@/router/modules/data-statistics/Broadcast';

const Index = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/Index');

const router = [
  {
    path: 'data-statistics',
    component: Index,
    children: [
      { path: '', redirect: 'broadcast' },
      ...Broadcast,
    ],
  },
];

export default router;
