import {
  getJobs,
  getJobsGrouped,
  getOrgTree,
  getAppJumpPage,
  getMenu,
  getReportType,
  getCourses,
  getExams,
} from '@/api/Common';

const Common = {
  namespaced: true,
  state: {
    jobList: [],
    jobListGrouped: [],
    orgTree: [],
    courseList: [],
    examList: [],
    appJumpPage: [],
    menu: [],
    reportType: [],
    dealStatus: [],
    studentMenu: [],
  },
  mutations: {
    updateJobList(state, list) {
      state.jobList = list;
    },
    updateJobListGrouped(state, list) {
      state.jobListGrouped = list;
    },
    updateOrgTree(state, list) {
      state.orgTree = list;
    },
    updateCourseList(state, list) {
      state.courseList = list || [];
    },
    updateExamList(state, list) {
      state.examList = list || [];
    },
    updateAppJumpPage(state, list) {
      state.appJumpPage = list || [];
    },
    updateMenu(state, menu) {
      state.menu = menu || [];
    },
    updateReportType(state, list) {
      state.reportType = list;
    },
    updateReportStatus(state, list) {
      state.dealStatus = list;
    },
    updateStudentMenu(state, menu) {
      state.studentMenu = menu || [];
    },
  },
  actions: {
    async getJobs({ commit }) {
      const data = await getJobs();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateJobList', result);
    },

    async getJobsGrouped({ commit }) {
      const data = await getJobsGrouped();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateJobListGrouped', result);
    },

    async getOrgTree({ commit }) {
      const data = await getOrgTree();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      let arr = [...result];
      let i = 1;
      let depth = 0;
      arr[0].isFirst = true;
      for (i = 0; i < arr.length; i += 1) {
        if (arr[i].isFirst) {
          depth += 1;
          if (arr[i].children?.length) {
            arr[i].children[0].isFirst = true;
          }
        }
        arr[i].depth = depth;

        arr.push(...(arr[i].children || []));
      }
      commit('updateOrgTree', result);
    },

    async getCourses({ commit }) {
      const data = await getCourses();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateCourseList', result);
    },

    async getExams({ commit }) {
      const data = await getExams();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateExamList', result);
    },

    async getAppJumpPage({ commit }) {
      const data = await getAppJumpPage();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateAppJumpPage', result);
    },

    async getReportType({ commit }) {
      const data = await getReportType();
      if (!data || data.error_code) {
        return [];
      }
      const obj = {};
      obj.id = '-1';
      obj.title = '不限';
      let result = data?.data?.report_type || [];
      result.unshift(obj);
      let dealStatus = data?.data?.inform_deal_status_config || [];
      dealStatus.unshift(obj);
      commit('updateReportType', result);
      commit('updateReportStatus', dealStatus);
    },

    async getMenu({ commit }) {
      const data = await getMenu();
      if (!data || data.error_code) {
        return [];
      }
      const menu = data?.data || [];
      const result = [];

      for (let key in menu) {
        if (Object.hasOwnProperty.call(menu, key)) {
          result.push(menu[key]);
        }
      }

      commit('updateMenu', result);
      return result;
    },

  },
};

export default Common;
