class Common {
  static getDefaultPagination () {
    return Object({}, {
      current: 1,
      defaultCurrent: 1,
      defaultPageSize: 10,
      pageSize: 10,
      pageSizeOptions: ['10', '20', '30'],
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: (total) => `共 ${total} 条`,
      total: 0,
    });
  }

  static updatePagination (pager, pagination) {
    pager = pager || {};
    pagination = pagination || {};

    return Object.assign({}, pager, {
      total: pagination?.total || 0,
      current: pagination?.current_page || 1,
      pageSize: pagination?.per_page || pager.defaultPageSize,
    });
  }

  static getSelectedItems (checkedKeys, items, options) {
    checkedKeys = checkedKeys || [];
    items = [].concat(items) || [];
    options = options || {};
    const id = options.id || 'id';
    const name = options.name || 'name';
    let result = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].children && items[i].children.length) {
        items.push(...items[i].children);
      }
      if (checkedKeys.includes(items[i][id])) {
        result.push({ [id]: items[i][id], [name]: items[i][name] });
      }
    }

    return result;
  }

  static getUnLeaves (items) {
    items = [].concat(items) || [];
    let result = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].children && items[i].children.length) {
        items.push(...items[i].children);
        result.push({ ...items[i] });
      }
    }

    return result;
  }

  static getFirstLeafPath (items) {
    items = [].concat(items) || [];
    let result = [];

    while (items?.length) {
      result.push({ ...items[0] });
      items = items[0]?.children;
    }

    return result;
  }

  /**
   * 转换时间字符串
   * @param timeString
   * @param backType 返回值类型。0：字符串，1：时间戳，2：短时间戳
   * @returns {number|string|number|Date|*}
   */
  static transferIosTimeString (timeString, backType) {
    if (!timeString) {
      return backType ? 0 : '';
    }
    timeString = timeString.replace(/-/g, '/');
    const time = new Date(timeString);
    const shortTime = Math.round(time * 0.001);
    switch (backType) {
      case 0:
        return timeString;
      case 1:
        return time;
      case 2:
        return shortTime;
    }
    return '';
  }

  /**
   * 格式化日期时间
   * @param {number} timestamp
   * @param {boolean} showSec
   * @returns {string}
   */
  static formatDateTime (timestamp, showSec) {
    const time = new Date(timestamp);

    const date = Common.formatDate(time);
    const dayTime = Common.formatTime(time, showSec);

    return `${date} ${dayTime}`;
  }


  /**
   * 格式化日期
   * @param {number} time Date 对象
   * @returns {string}
   */
  static formatDate (time) {
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getDate();

    m = Common.formatNumber(m, 2);
    d = Common.formatNumber(d, 2);

    return `${y}-${m}-${d}`;
  }


  /**
   * 格式化时间 24 小时制
   * @param {Date} time Date 对象
   * @param {boolean} showSec 显示秒
   * @returns {string}
   */
  static formatTime (time, showSec) {
    let h = time.getHours();
    let mm = time.getMinutes();
    let s = time.getSeconds();

    h = Common.formatNumber(h, 2);
    mm = Common.formatNumber(mm, 2);
    s = Common.formatNumber(s, 2);

    return `${h}:${mm}${showSec ? (':' + s) : ''}`;
  }

  /**
   * 格式化时间 12 小时制
   * @param {number} time Date 对象
   * @returns {string}
   */
  static formatTime12HoursSystems (time) {
    let sys = '上午';
    let h = time.getHours();
    let mm = time.getMinutes();
    // let s = time.getSeconds();

    if (+h >= 12) {
      sys = '下午';
    }

    h = Common.formatNumber(h, 2);
    mm = Common.formatNumber(mm, 2);
    // s = Common.formatNumber(s, 2);

    return `${sys} ${h}:${mm}`;
  }

  /**
   * 格式化数字，不足位 0
   * @param number 数字
   * @param bits 位数
   * @returns {string}
   */
  static formatNumber (number, bits) {
    bits = bits || 2;
    let str = number + '';
    const len = str.length;
    if (len < bits) {
      for (let i = bits - len; i > 0; i--) {
        str = '0' + str;
      }
      return str;
    }
    return str;
  }

  /**
   * 格式化时间段
   * @param duration
   * @return {string}
   */
  static formatDurationTime (duration) {
    if (duration <= 0) {
      return '00:00:00';
    }
    const h = Common.formatNumber(Math.floor(duration / 3600), 2);
    const m = Common.formatNumber(Math.floor(duration % 3600 / 60), 2);
    const s = Common.formatNumber(Math.floor(duration % 60), 2);

    return `${h}:${m}:${s}`;
  }

  /**
   * 获取一个 len 位的随机字符串
   * @param len
   */
  static getRandomString (len) {
    len = len || 32;
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }

  static getStyle (el, attr) {
    if (!el) {
      return '';
    }
    if (el.style[attr]) {
      return el.style[attr];
    }
    if (el.currentStyle) {
      return el.currentStyle[attr];
    }
    if (window.getComputedStyle) {
      const style = window.getComputedStyle(el);
      return style[attr];
    }
    return '';
  }

  static downloadFile (url, fileName) {
    const link = document.createElement('a');

    link.download = fileName || new Date().getTime();
    link.style.display = 'none';
    link.href = url;

    const e = document.createEvent('MouseEvents');
    e.initEvent('click', false, false);

    link.dispatchEvent(e);
  }
  static isMobile (data) {
    var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (data.length === 11 && reg.test(data)) {
      return true;
    }
    return false;
  }
  static isIdCard (data) {

    var reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    console.log(data, reg.test(data));
    if (data.length === 18 && reg.test(data)) {
      return true;
    }
    return false;
  }
  static isVerificationCode (data) {
    if (/^\d{6}$/.test(data)) {
      return true;
    }
    return false;
  }
}

export default Common;
