const prefix = '/index/studentFiles/';

const navs = [
  {
    id: '1', label: '学员档案', path: '/', children: [
      {
        id: '1-1', label: '档案列表', path: `${prefix}list`, childrenPath: [
          `${prefix}list/query`,
          `${prefix}list/result`,
          `${prefix}list/file`,
        ],
      },
      {
        id: '1-2', label: '学习查询', path: `${prefix}study`,
      },
      {
        id: '1-3', label: '销售查询', path: `${prefix}sale`,
      },
    ],
  },
];

export default navs;
