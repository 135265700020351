const EntranceList = () => import(/* webpackChunkName: "LearnMap" */ '@views/indexConfig/EntranceList');
const OperationEntrance = () => import(/* webpackChunkName: "LearnMap" */ '@views/indexConfig/OperationEntrance');

const entranceRouter = [
  {
    name: 'EntranceList',
    path: 'list',
    component: EntranceList,
  },
  {
    name: 'OperationEntrance',
    path: 'operation',
    component: OperationEntrance
  }
];

export default entranceRouter;
