import {getTagListForSelect, getTopicListForSelect} from '@/api/onlineCommunity/Index';

const defaultQuizQuery = {
  quizName: '',
  answerFilter: 'eq',
  answerCount: undefined,
  auditingStatus: 0,
  topic: '', // 逗号分割的多个 id 字符串
  tag: '', // 逗号分割的二级 id 字符串
  quizUserName: '',
  range: [],
};

const defaultAnswerQuery = {
  answerName: '',
  commentFilter: 'eq',
  commentCount: undefined,
  range: [],
  answerUserName: '',
  acceptStatus: 0,
  auditingStatus: 0,
};

const defaultCommentQuery = {
  commentUserName: '',
  range: [],
};

const defaultReplyQuery = {
  replyUserName: '',
  range: [],
};


const OnlineCommunity = {
  namespaced: true,
  state: {
    quizQuery: {},
    answerQuery: {},
    commentQuery: {},
    replyQuery: {},

    tagListForSelect: [],
    topicListForSelect: [],
  },
  mutations: {
    updateQuizQuery(state, query) {
      state.quizQuery = Object.assign({}, defaultQuizQuery, state.quizQuery, query || {});
    },
    resetQuizQuery(state) {
      state.quizQuery = Object.assign({}, defaultQuizQuery);
    },

    updateAnswerQuery(state, query) {
      state.answerQuery = Object.assign({}, defaultAnswerQuery, state.answerQuery, query || {});
    },
    resetAnswerQuery(state) {
      state.answerQuery = Object.assign({}, defaultAnswerQuery);
    },

    updateCommentQuery(state, query) {
      state.commentQuery = Object.assign({}, defaultCommentQuery, state.commentQuery, query || {});
    },
    resetCommentQuery(state) {
      state.commentQuery = Object.assign({}, defaultCommentQuery);
    },

    updateReplyQuery(state, query) {
      state.replyQuery = Object.assign({}, defaultReplyQuery, state.replyQuery, query || {});
    },
    resetReplyQuery(state) {
      state.replyQuery = Object.assign({}, defaultReplyQuery);
    },

    updateTagListForSelect(state, list) {
      list.forEach(i => {
        i._checked_ = i._checked_ || false;
        if (i?.labels?.length) {
          i.labels.forEach(t => t._checked_ = t._checked_ || false);
        }
      });
      state.tagListForSelect = list || [];
    },

    updateTopicListForSelect(state, list) {
      state.topicListForSelect = list || [];
    },
  },
  actions: {
    async getTagListForSelect({commit}) {
      const data = await getTagListForSelect();
      if (!data || data.error_code) {return Promise.reject(data || {reason: 'ERROR'});}
      commit('updateTagListForSelect', data?.data || []);
      return Promise.resolve(data);
    },

    async getTopicListForSelect({commit}, {startTimeStr, endTimeStr} = {}) {
      const params = {};
      startTimeStr && (params.start_day = startTimeStr);
      endTimeStr && (params.end_day = endTimeStr);

      const data = await getTopicListForSelect(params);
      if (!data || data.error_code) {return Promise.reject(data || {reason: 'ERROR'});}
      commit('updateTopicListForSelect', data?.data || []);
      return Promise.resolve(data);
    },
  },
};

export default OnlineCommunity;
