import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';

/* 直播流量查询 */

// 直播流量统计--数据总览--echart 柱状图
export function getBroadcastBarData(params) {
  return request({
    url: '/broadcast/admin/index_chart',
    method: 'post',
    params,
  });
}

// 直播流量统计--数据总览--直播统计表格
export function getBroadcastList(params) {
  return request({
    url: '/broadcast/admin/index_table',
    method: 'post',
    params,
  });
}

// 直播流量统计--数据总览--直播统计表格--直播明细
export function getBroadcastLiveDetail(params) {
  return request({
    url: '/broadcast/admin/index_table_live_detail',
    method: 'post',
    params,
  });
}

// 直播流量统计--数据总览--直播统计表格--人员明细
export function getBroadcastUserDetail(params) {
  return request({
    url: '/broadcast/admin/index_table_play_detail',
    method: 'post',
    params,
  });
}


// 直播流量统计--数据总览--直播统计表格-导出 excel
export function getBroadcastListDownload(params) {
  let url = '/broadcast/admin/index_table';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'POST',
    token: store.state.User.token || '',
  });
}


// 直播流量统计--数据总览--直播统计表格--直播明细-导出 excel
export function getBroadcastLiveDetailDownload(params) {
  let url = '/broadcast/admin/index_table_live_detail';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'POST',
    token: store.state.User.token || '',
  });
}


// 直播流量统计--数据总览--直播统计表格--人员明细-导出 excel
export function getBroadcastUserDetailDownload(params) {
  let url = '/broadcast/admin/index_table_play_detail';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'POST',
    token: store.state.User.token || '',
  });
}


// 直播列表
export function getBroadcastLiveList(params) {
  return request({
    url: '/broadcast/admin/LiveGift/getLiveList',
    method: 'get',
    params,
  });
}

// 观看数据
export function getBroadcastWatchData(params) {
  return request({
    url: '/broadcast/admin/LiveGift/getLiveviewData',
    method: 'get',
    params,
  });
}

// 观看数据-导出 excel
export function getBroadcastWatchDataDownload(params) {
  let url = '/broadcast/admin/LiveGift/getLiveviewData';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 直播热度数据
export function getBroadcastHeatData(params) {
  return request({
    url: '/broadcast/admin/LiveGift/getLiveDegreeOfHeatList',
    method: 'get',
    params,
  });
}

// 观看数据-导出 excel
export function getBroadcastHeatDataDownload(params) {
  let url = '/broadcast/admin/LiveGift/getLiveDegreeOfHeatList';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 直播热度数据明细
export function getBroadcastHeatDetailData(params) {
  return request({
    url: '/broadcast/admin/LiveGift/getLiveInfoById',
    method: 'get',
    params,
  });
}

// 直播热度数据明细-导出 excel
export function getBroadcastHeatDetailDataDownload(params) {
  let url = '/broadcast/admin/LiveGift/getLiveInfoById';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
