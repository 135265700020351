<template>
  <div class="app-common-title">
    <div class="flex">
      <a-breadcrumb separator="/">
        <a-breadcrumb-item v-for="(item, idx) of titles" :key="idx">{{ item.title || '' }}</a-breadcrumb-item>
      </a-breadcrumb>
      <div v-if="allNum">({{ allNum }})</div>
    </div>
    <div class="buttons ml-30">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonTitle',
  props: {
    titles: {type: Array, default: () => []},
    allNum: {
      type: Number,
      default: () => 0
    }
  },
}
</script>

<style scoped lang="scss">
.app-common-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 20px 10px 30px;
  white-space: nowrap;
}
</style>

