<template>
  <div class="show-login show-left">
    <div><h1>登录</h1></div>
    <div class="login-card">
      <div><h3>用户名</h3></div>
      <div class="login-input">
        <a-input
          v-model="data.code"
          @keyup="onChangeLogin($event)"
          @change="validationCode"
          size="default"
          placeholder="请输入手机号"
        />
      </div>
      <div v-if="!rule.isCodeCheck" class="login-tip">
        <span>用户名格式不正确</span>
      </div>
    </div>
    <div class="login-card">
      <div><h3>密码</h3></div>
      <div>
        <a-input-password
          v-model="data.password"
          @keyup="onChangeLogin($event)"
          @change="validationPassWord"
          size="default"
          placeholder="密码不少于6位"
        />
      </div>
      <div v-if="!rule.isPwdCheck" class="login-tip">
        <span>密码少于6位</span>
      </div>
    </div>
    <div class="login-card">
      <div><h3>验证码</h3></div>
      <div>
        <a-input
          v-model="data.captcha"
          @keyup="onChangeLogin($event)"
          @change="validationCaptcha"
          size="default"
          placeholder="请输入验证码"
        />
      </div>
      <div v-if="!rule.isCaptcha" class="login-tip">
        <span>请输入验证码</span>
      </div>
    </div>
    <div>
      <img :src="'/api/eqlearn/Login/createCaptcha?timestamp=' + timestamp" class="captcha" @click="updateCaptcha"/>
    </div>

    <div class="login-btn">
      <span class="left">
        <a-button type="primary" @click="onLogin"> 登录 </a-button></span
      >
      <span class="right">
        <a-button v-if="false" @click="onCheck"> 激活账号 </a-button>
      </span>
    </div>
    <div class="btn-item1">
      <span><a @click="onPassword">忘记密码？</a></span>
      <!-- <span><a @click="toLogin">账号或密码登录</a></span> -->
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import { login,jsEncrypt } from '@/api/userConfig/userCheck.js';
import { setToken } from '@/utils/Token';
import Loading from '@/components/Loading.vue';

export default {
  name: 'ShowLogin',
  components: {
    Loading
  },
  data() {
    return {
      data: {
        code: '',
        password: '',
        captcha: '',
      },
      rule: {
        isCodeCheck: true,
        isPwdCheck: true,
        isCaptcha: true,
      },
      loading: false,
      timestamp: new Date().getTime(),
    };
  },
  computed: {
    callback_url() {
      return this?.$route?.query?.callback_url || undefined;
    }
  },
  methods: {
    //登录事件
    async onLogin() {
      if (this.validation()) {
        this.loading = true;
        let config = {
            username: this.data.code,
            time:this.timestamp,
            password: this.data.password
          }
        const data = await login({
          username: this.data.code,
          password: jsEncrypt(JSON.stringify(config)),
          code: this.data.captcha,
          loginType: 'pc',
          callback_url: this.callback_url
        }).finally(() => {
          this.loading = false;
          this.data.captcha = '';
          this.updateCaptcha();
        });
        console.log('data',data)
       if (!(data && data.error_code === 0)) {
          this.$message.error(data.message);
          return;
        }
        setToken(data.data.user_token);
        const path = `${data.data.url}`;
        setTimeout(() => {
          this.$redirect(path || '');
          // this.loading = false;
        }, 1000);
        this.$message.success('登录成功');
      }
    },
    //Enter 登录
    onChangeLogin(e) {
      console.log(e);
      if (e.keyCode === 13) {
        this.onLogin();
      }
    },
    // 跳转到激活账号
    onCheck() {
      this.$emit('onCheck');
    },
    // 跳转到password
    onPassword() {
      this.$emit('onPassword');
    },
    //验证
    validation() {
      let result = true;
      if (!this.validationCode()) {
        result = false;
      }
      if (!this.validationPassWord()) {
        result = false;
      }
      if (!this.validationCaptcha()) {
        result = false;
      }
      return result;
    },
    //验证账号
    validationCode() {
      this.rule.isCodeCheck = true;
      if (!(this.data.code && this.data.code.length > 0)) {
        this.rule.isCodeCheck = false;
      }
      return this.rule.isCodeCheck;
    },
    //验证密码
    validationPassWord() {
      this.rule.isPwdCheck = true;
      if (!(this.data.password && this.data.password.length >= 6)) {
        this.rule.isPwdCheck = false;
      }
      return this.rule.isPwdCheck;
    },
    validationCaptcha() {
      this.rule.isCaptcha = true;
      if (!this.data.captcha) {
        this.rule.isCaptcha = false;
      }
      return this.rule.isCaptcha;
    },

    // 获取图形验证码
    updateCaptcha() {
      this.timestamp = new Date().getTime();
    }
  }
};
</script>
<style lang="less" scoped>
.show-login {
  width: 100%;
  height: 100%;
  div {
    margin: 0 auto;
    h1 {
      font-size: 25px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      text-align: center;
    }
    h3 {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #83868e;
      margin: 0;
      padding: 0 0 10px 0;
    }
    .login-input {
      height: 40px;
    }
    ::v-deep .ant-input {
      height: 40px;
      border: none;
    }
    ::v-deep .ant-input::placeholder {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dadde5;
    }
  }
  .login-card {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .login-verificationcode {
    ::v-deep .ant-input-group-addon {
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
    }
    ::v-deep .ant-input-group {
      padding: 0;
      margin: 0;
      border: 0;
      border: 1px solid #d9d9d9;
    }
    ::v-deep .ant-btn {
      border: 1px solid #f15952;
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      background: #ffffff !important;
    }
    ::v-deep .ant-btn-primary {
      border: 1px solid #f15952;
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      background: #ffffff !important;
    }
    ::v-deep .ant-input {
      border: 0;
    }
  }
  .login-btn {
    display: flex;
    align-items: center;
    height: 44px;
    margin: 33px 0 0 0;
    padding: 0;
    span {
      flex: 1;
      .btn-item1 {
        flex: 1;
      }
      ::v-deep button {
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 120px;
        display: inline-block;
        height: 44px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff5a52;
        border: 1px solid #ff5a52;
      }
      ::v-deep .ant-btn-primary {
        background: #ff5a52;
        color: #ffffff;
      }
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
  }
  .login-tip {
    margin: 5px 0 0 0;
    text-align: right;
    color: #ff5a52;
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .btn-item1 {
    flex: 1;
    margin-top: 10px;
    a {
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #333333;
    }
  }
}

.captcha {
  height: 62px;
  cursor: pointer;
}
</style>
