<template>
  <div class="full-size">
    <common-title :titles="titles"/>

    <collapse-training-info :id="id" @get-training-type="getTrainingType"/>

    <tab-detail-page :menus="menus" :show-menus="true" :query="$route.query" style="height: auto;"/>
  </div>
</template>

<script>
import CommonTitle from '@/components/CommonTitle.vue';
import TabDetailPage from '@/components/TabDetailPage.vue';
import CollapseTrainingInfo from '../../components/CollapseTrainingInfo.vue';

const menus = [
  {
    id: '1',
    title: '基本情况',
    path: `/index/training/unified/detail/index/basic`,
    disabled: false,
    hidden: false,
  },
  {
    id: '2',
    title: '学习进度',
    path: `/index/training/unified/detail/index/progress`,
    disabled: true,
    hidden: true,
  },
  {
    id: '3',
    title: '回传材料',
    path: `/index/training/unified/detail/index/material`,
    disabled: true,
    hidden: false,
  },
];

export { menus };

export default {
  name: 'Detail',
  components: {
    CommonTitle,
    TabDetailPage,
    CollapseTrainingInfo,
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '内训计划列表' }, { title: '内训详情' }],
      menus,

      id: 0,
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;
  },
  beforeDestroy() {
    this.$store.commit('Training/updateUnifiedDetailOrgIds', '');
  },
  methods: {
    getTrainingType(ev) {
      this.menus = [...this.menus.map((i) => ({ ...i, hidden: i.id === '2' && ev !== 1 }))];
    },
  },
};
</script>

<style scoped lang="scss">

</style>
