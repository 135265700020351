const Index = () => import(/* webpackChunkName: "Training" */  '@views/training/selfBuilt/Index');

const TrainingSelfBuiltList = () => import(/* webpackChunkName: "Training" */  '@views/training/selfBuilt/List');
const TrainingSelfBuiltStudent = () => import(/* webpackChunkName: "Training" */  '@views/training/selfBuilt/Student');
const TrainingSelfBuiltScore = () => import(/* webpackChunkName: "Training" */  '@views/training/selfBuilt/Score');


const Router = [
  {
    path: 'selfBuilt',
    component: Index,
    children: [
      { path: '', redirect: 'list' },
      {
        name: 'TrainingSelfBuiltList',
        path: 'list',
        component: TrainingSelfBuiltList,
      },
      {
        name: 'TrainingSelfBuiltStudent',
        path: 'student',
        component: TrainingSelfBuiltStudent,
      },
      {
        name: 'TrainingSelfBuiltScore',
        path: 'score',
        component: TrainingSelfBuiltScore,
      },
    ],
  },
];

export default Router;
