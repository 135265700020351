const MapPathList = () => import(/* webpackChunkName: "LearnMap" */ '@views/learnMap/List');
const MapPathDetail = () => import(/* webpackChunkName: "LearnMap" */ '@views/learnMap/Detail');
const MapPathDetailBasic = () => import(/* webpackChunkName: "LearnMap" */ '@views/learnMap/Basic');
const MapPathDetailLevel = () => import(/* webpackChunkName: "LearnMap" */ '@views/learnMap/Level');
const MapPathDetailVerification = () => import(/* webpackChunkName: "LearnMap" */ '@views/learnMap/Verification');
const MapPathUpload = () => import(/* webpackChunkName: "LearnMap" */ '@views/learnMap/Upload');

const mapRouter = [
  {
    name: 'MapPathList',
    path: 'list',
    component: MapPathList,
  },
  {
    name: 'MapPathDetail',
    path: 'detail',
    component: MapPathDetail,
    children: [
      {
        path: '',
        redirect: 'basic',
      },
      {
        name: 'MapPathDetailBasic',
        path: 'basic',
        component: MapPathDetailBasic,
      },
      {
        name: 'MapPathDetailLevel',
        path: 'level',
        component: MapPathDetailLevel,
      },
      {
        name: 'MapPathDetailVerification',
        path: 'verification',
        component: MapPathDetailVerification,
      },
    ],
  },
  {
    path: 'upload',
    component: MapPathUpload,
  },
];

export default mapRouter;
