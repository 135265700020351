const Index = () => import(/* webpackChunkName: "StudentFiles" */  '@views/routerView/Index.vue');

const ListQuery = () => import(/* webpackChunkName: "StudentFiles" */  '@views/studentFiles/ListQuery.vue');
const List = () => import(/* webpackChunkName: "StudentFiles" */  '@views/studentFiles/List.vue');
const Study = () => import(/* webpackChunkName: "StudentFiles" */  '@views/studentFiles/Study.vue');
const Sale = () => import(/* webpackChunkName: "StudentFiles" */  '@views/studentFiles/Sale.vue');
const File = () => import(/* webpackChunkName: "StudentFiles" */  '@views/studentFiles/File.vue');

const routes = [
  {
    path: 'studentFiles',
    component: Index,
    meta: { title: '学员档案' },
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: Index,
        meta: { title: '档案列表' },
        children: [
          { path: '', redirect: 'query' },
          { path: 'query', component: ListQuery },
          { path: 'result', component: List },
          {
            path: 'file',
            component: File,
            meta: { title: '档案详情', navHide: true, breadcrumbHide: true },
          },
        ],
      },
      { path: 'study', component: Study, meta: { title: '学习查询' } },
      { path: 'sale', component: Sale, meta: { title: '销售查询' } },
    ],
  },
];

export default routes;
