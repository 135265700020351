<template>
  <div class="login">
    <div class="login-index">
      <div class="login-table">
        <div class="left">
          <ShowIDCardCheck
            :idCard="idCard"
            v-if="type === 'idCard'"
            @onNext="onNext"
            @onBack="onBack"
          ></ShowIDCardCheck>
          <ShowLogin
            v-if="type === 'login'"
            @onPassword="onPassword"
            @onCheck="onCheck"
          ></ShowLogin>
          <ShowMobileCheck
            :idCard="idCard"
            v-if="type === 'mobile'"
            @onBack="onBack"
            @onStep="onStep"
          ></ShowMobileCheck>
          <ShowPassword
            v-if="type === 'password'"
            @onBack="onBack"
          ></ShowPassword>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="login-bottom">
      <template v-if="false">
        Copyright Wemark 2016-{{ nowYear }}
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备16046228号</a>
      </template>

      <template v-if="true">
        <a href="https://beian.miit.gov.cn" target="_blank" style="margin-right: 10px;">
          粤ICP备16046228号-3
          <img src="./assets/gongan.png" alt="" class="gongan">
        </a>
         ©深圳市维玛科技有限公司 版权所有
      </template>
    </div>
  </div>
</template>

<script>
import ShowIDCardCheck from './components/ShowIDCardCheck.vue';
import ShowLogin from './components/ShowLogin.vue';
import ShowMobileCheck from './components/ShowMobileCheck.vue';
import ShowPassword from './components/ShowPassword.vue';

export default {
  data() {
    return {
      type: this.$route?.query?.type ?? 'login',
      idCard: '',
      mobile: '',
      nowYear: new Date().getFullYear(),
    };
  },
  components: {
    ShowIDCardCheck,
    ShowLogin,
    ShowMobileCheck,
    ShowPassword
  },
  methods: {
    onNext(data) {
      this.type = 'mobile';
      this.idCard = data;
    },
    onPassword() {
      this.type = 'password';
      console.log('password');
    },
    onCheck() {
      this.type = 'idCard';
      console.log('idCard');
    },
    onBack() {
      this.type = 'login';
      this.idCard = '';
      this.mobile = '';
      console.log('idCard');
    },
    onStep() {
      this.type = 'idCard';
    }
  },
  watch: {
    $route: {
      handler(newRouter) {
        console.log(newRouter);
        this.type = this.$route?.query?.type ?? 'login';
      }
    }
  }
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 120px 0 0 0;

  .login-index {
    width: 1200px;
    margin: auto;
    background-color: #fdf8f8;
    height: 670px;
    .login-table {
      display: flex;
      height: 100%;
      .left {
        flex: 1;
        ::v-deep .show-left {
          margin: 0 auto;
          width: 300px;
          height: auto;
          margin-top: 135px;
        }
      }
      .right {
        flex: 1;
        float: left;
        height: 100%;
        background-image: url(/admin/img/r12.png);
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
  .login-bottom {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
::v-deep .ant-input:focus {
  border: none;
  box-shadow: none;
}
::v-deep .ant-input:hover {
  border: none;
  box-shadow: none;
}
</style>
