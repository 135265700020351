const navs = [
  {
    id: '1', label: '筹建店查询', path: '/', children: [
      {
        id: '1-1',
        label: '新体验中心验收查询',
        path: '/index/preparation/acceptance/query',
      },
      {
        id: '1-2',
        label: '新体验中心验收配置',
        path: '/index/preparation/acceptance/setting',
      },
    ],
  },
];

export default navs;
