const HappyNewYear = () => import(/* webpackChunkName: "ActivityCenter" */ '@views/activityCenter/happy-new-year/Index.vue');

const activityRouter = [
  {
    name: 'happy-new-year',
    path: 'happy-new-year',
    component: HappyNewYear,
  },
];

export default activityRouter;
