const TokenKey = 'token'; // AdminToken

export function getToken () {
  return localStorage.getItem(TokenKey);
}

export function setToken (token) {
  sessionStorage.setItem(TokenKey, token);
  return localStorage.setItem(TokenKey, token);
}

export function removeToken () {
  return setToken('');
}
