<template>
  <div class="show-idcard-check show-left">
    <div><h1>激活账号</h1></div>
    <div class="login-card">
      <div><h3>身份证校验</h3></div>
      <div class="login-input">
        <a-input
          v-model="data.idcard"
          @change="validationIdCard"
          size="default"
          placeholder="请输入二代身份证号"
        />
      </div>
      <div class="login-tip" v-if="!rule.isIdCardCheck">
        <span>身份证号格式不正确</span>
      </div>
    </div>
    <div class="login-btn">
      <span class="left"> <a-button @click="onBack"> 返回 </a-button></span>
      <span class="right">
        <a-button type="primary" @click="onNext" :disabled="!isIdCard">
          下一步
        </a-button>
      </span>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import { exists } from '@/api/userConfig/userCheck.js';
import Common from '@/utils/Common';
import Loading from '@/components/Loading.vue';

export default {
  name: 'ShowIDCardCheck',
  components: {
    Loading
  },
  data() {
    return {
      data: {
        idcard: this.idCard
      },
      rule: {
        isIdCardCheck: true
      },
      isIdCard: false,
      loading:false
    };
  },
  props: {
    idCard: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    onBack() {
      this.$emit('onBack');
    },
    async onNext() {
      if (!this.validationIdCard()) {
        return;
      }
      this.loading =true;
      const data = await exists({ idcard: this.data.idcard }).finally(() => {
        this.loading = false;
      });
      console.log(data);
      if (!(data && data.error_code === 0)) {
        this.$message.error(data.message);
        this.isIdCard = false;
        return;
      }
      if (data.data.exists === 1) {
        this.$emit('onNext', this.data.idcard);
      } else {
        this.$message.error(data.message);
        this.isIdCard = false;
      }
    },
    //验证身份证号
    validationIdCard() {
      let result = true;
      this.rule.isIdCardCheck = true;
      this.isIdCard = true;
      if (!(this.data.idcard && Common.isIdCard(this.data.idcard))) {
        this.rule.isIdCardCheck = false;
        this.isIdCard = false;
        result = false;
      }
      return result;
    },
    createdValidationIdCard() {
      this.isIdCard = true;
      if (!(this.data.idcard && Common.isIdCard(this.data.idcard))) {
        this.isIdCard = false;
      }
    }
  },
  created() {
    this.createdValidationIdCard();
  }
};
</script>

<style lang="less" scoped>
.show-idcard-check {
  width: 100%;
  height: 100%;
  div {
    margin: 0 auto;
    h1 {
      font-size: 25px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      text-align: center;
    }
    h3 {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #83868e;
      margin: 0;
      padding: 0 0 10px 0;
    }
    .login-input {
      height: 40px;
    }
    ::v-deep .ant-input {
      height: 40px;
      border: none;
      box-shadow: none;
    }
    ::v-deep .ant-input::placeholder {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dadde5;
    }
  }
  .login-card {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .login-verificationcode {
    ::v-deep .ant-input-group-addon {
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
    }
    ::v-deep .ant-input-group {
      padding: 0;
      margin: 0;
      border: 0;
      border: 1px solid #d9d9d9;
    }
    ::v-deep .ant-btn {
      border: 1px solid #f15952;
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      background: #ffffff !important;
    }
    ::v-deep .ant-btn-primary {
      border: 1px solid #f15952;
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      background: #ffffff !important;
    }
    ::v-deep .ant-input {
      border: 0;
    }
  }
  .login-btn {
    display: flex;
    align-items: center;
    height: 44px;
    margin: 33px 0 0 0;
    padding: 0;
    span {
      flex: 1;
      .btn-item1 {
        flex: 1;
      }
      ::v-deep button {
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 120px;
        display: inline-block;
        height: 44px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff5a52;
        border: 1px solid #ff5a52;
      }
      ::v-deep .ant-btn-primary {
        background: #ff5a52;
        color: #ffffff;
      }
      ::v-deep button[disabled='disabled'] {
        color: #d9d9d9 !important;
        background-color: #f5f5f5 !important;
        border: 1px solid #d9d9d9 !important;
      }
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
  }
  .login-tip {
    margin: 5px 0 0 0;
    text-align: right;
    color: #ff5a52;
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .btn-item1 {
    flex: 1;
    margin-top: 5px;
  }
}
</style>
