<template>
  <tab-detail-page :titles="titles"
                   :menus="menus"
                   :query="$route.query" />
</template>

<script>
import TabDetailPage from '@/components/TabDetailPage';

const menus = [
  {
    id: '1',
    title: '基础信息',
    path: `/index/training/unified/independent/basic`,
  },
  {
    id: '2',
    title: '培训材料',
    path: `/index/training/unified/independent/science`,
  },
  {
    id: '3',
    title: '人数设置',
    path: `/index/training/unified/independent/content`,
  },
  {
    id: '4',
    title: '回传材料',
    path: `/index/training/unified/independent/material`,
  },
  {
    id: '5',
    title: '发布确认',
    path: `/index/training/unified/independent/confirm`,
  },
];

export { menus };

export default {
  name: 'Detail',
  components: {
    TabDetailPage,
  },
  data() {
    return {
      titles: [
        { title: '内训管理' },
        { title: '创建内训' },
        { title: '自主内训' },
      ],
      menus,
    };
  },
  created() {
    this.updateMenus(this.$route.query?.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.updateMenus(to.query?.id);
    next();
  },
  methods: {
    updateMenus(id) {
      if (id) {
        this.menus.forEach((m) => (m.disabled = false));
      } else {
        this.menus.forEach((m, i) => {
          m.disabled = i !== 0;
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
