import Job from '@/router/modules/userConfig/Job';

const Index = () => import(/* webpackChunkName: "ActivityCenter" */  '@views/userConfig/Index');

const scriptRouter = [
  {
    path: 'userConfig',
    component: Index,
    children: [
      {path: '', redirect: 'certList'},
      ...Job,
    ],
  },
];

export default scriptRouter;
