import request from '@/utils/Request';

// 活动中心-后台-活动中心数据
export function getActivityList(data) {
  return request({
    url: '/admin/ActivityCenter/list',
    method: 'post',
    data,
  });
}

// 活动中心-后台-已上架的活动列表
export function getOnActivityList(data) {
  return request({
    url: '/admin/ActivityCenter/sortList',
    method: 'post',
    data,
  });
}

// 活动中心-后台-确认置顶/取消置顶
export function setTop(data) {
  return request({
    url: '/admin/ActivityCenter/confirmTop',
    method: 'post',
    data,
  });
}

// 活动中心-后台-活动上架
export function pullOn(id) {
  const data = {id: id, status: 1};
  return request({
    url: '/admin/ActivityCenter/underCarriages',
    method: 'post',
    data,
  });
}

// 活动中心-后台-活动下架
export function pullOff(id) {
  const data = {id: id, status: 2};
  return request({
    url: '/admin/ActivityCenter/underCarriages',
    method: 'post',
    data,
  });
}

// 活动中心-后台-获取活动详情
export function getActivity(params) {
  return request({
    url: '/admin/ActivityCenter/add',
    method: 'get',
    params,
  });
}

// 活动中心-后台-添加或修改
export function saveActivity(data) {
  return request({
    url: '/admin/ActivityCenter/add',
    method: 'post',
    data,
  });
}

// 活动中心-后台-跳转链接列表
export function getJumpLink(data) {
  return request({
    url: '/admin/ActivityCenter/getTurnArr',
    method: 'post',
    data,
  });
}

// 活动中心-后台-添加链接或修改链接
export function saveJumpLink(data) {
  return request({
    url: '/admin/ActivityCenter/linkInsert',
    method: 'post',
    data,
  });
}

// 活动中心-后台-删除链接
export function deleteJumpLink(data) {
  return request({
    url: '/admin/ActivityCenter/linkDel',
    method: 'post',
    data,
  });
}
