const Index = () => import(/* webpackChunkName: "Preparation" */  '@/views/preparation/acceptance/Index');

const Query = () => import(/* webpackChunkName: "Preparation" */  '@/views/preparation/acceptance/Query');
const Setting = () => import(/* webpackChunkName: "Preparation" */  '@/views/preparation/acceptance/Setting');

const routes = [
  {
    path: 'acceptance',
    component: Index,
    meta: { title: '筹建店查询' },
    children: [
      { path: '', redirect: 'query' },
      { path: 'query', component: Query, meta: { title: '验收查询' } },
      { path: 'setting', component: Setting, meta: { title: '验收配置' } },
    ],
  },
];

export default routes;
