<template>
  <div class="app-main">
    <div class="app-main-inner">
      <div v-if="breadcrumbShown && list && list.length" class="flex flex-start bread-crumb">
        <a-breadcrumb separator="/">
          <a-breadcrumb-item v-for="(item, idx) of list" :key="idx">{{ item.meta.title || '' }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <router-view class="app-main-router-view"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main',
  computed: {
    list() {
      const { matched } = this.$route || {};
      return matched.filter((i) => i?.meta?.title).map((i) => ({ path: i.path, meta: i.meta }));
    },
    breadcrumbShown() {
      return !this.$route.meta?.breadcrumbHide;
    },
  },
};
</script>

<style scoped lang="scss">
.app-main {
  //width: 100%;
  padding: 0 5px 5px;

  .app-main-inner {
    //width: 100%;
    padding-bottom: 10px;
    border-radius: 2px;
    background-color: $light01;
  }

  .app-main-router-view {
    //width: 100%;
  }

  .bread-crumb {
    flex: none;
    height: 50px;
    padding: 10px 20px 10px 20px;
    white-space: nowrap;
  }
}
</style>
