import request from '@/utils/Request';

// 问题-搜索文案查看
export function getSearchPlaceholder() {
  return request({
    url: `/ask/admin/asks/config/search`,
    method: 'get',
  });
}

// 问题-搜索文案编辑
export function setSearchPlaceholder(data) {
  return request({
    url: `/ask/admin/asks/config/search`,
    method: 'post',
    data
  });
}
