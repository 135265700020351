import Vue from 'vue';
import VueRouter from 'vue-router';

import Index from '@views/Index';
import Transition from '@views/Transition';
import login from  '@/views/login/Index.vue';

import frameRoutes from './frame';

Vue.use(VueRouter);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = [];

modulesFiles.keys()
  .filter(dir => dir.indexOf('Index.js') > -1)
  .reduce((ms, modulePath) => {
    const module = modulesFiles(modulePath).default;
    modules.push(...module);
    return module;
  }, {});


const routes = [
  { path: '', redirect: '/login' },
  { path: '/transition', component: Transition },
  { path: '/login', component: login },
  {
    path: '/index',
    component: Index,
    children: [
      { path: '', redirect: 'activities' },
      ...modules,
    ],
  },
  ...frameRoutes,
  { path: '/404', component: () => import('@/views/error/404') },
  { path: '*', redirect: '/404' },
]
const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
})

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
//修复路由重复点击报错的bug
// const originalPush = VueRouter.prototype.push
//   VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

export default router;
