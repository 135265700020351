import {getLinks} from '@/api/Common';

const Token = {
  namespaced: true,
  state: {
    token: '',
    homeUrl: '',
    logOutUrl: '',
  },
  mutations: {
    updateToken(state, token) {
      state.token = token || '';
    },
    updateHomeUrl(state, url) {
      state.homeUrl = url || '';
    },
    updateLogOutUrl(state, url) {
      state.logOutUrl = url || '';
    },
  },
  actions: {
    async getLinks({commit}) {
      const data = await getLinks();
      if (!data || data.error_codee) {
        return;
      }
      commit('updateHomeUrl', data?.data?.index?.url);
      commit('updateLogOutUrl', data?.data?.logout?.url);
    },
  },
};

export default Token;
