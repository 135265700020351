import request from '../utils/Request';
import requestUnlimited from '../utils/RequestUnlimited';

// 公共-岗位信息
export function getJobs() {
  return request({
    url: '/manage/select/jobs',
    method: 'get',
  });
}

// 公共-获取全部岗位
export function getJobsGrouped() {
  return request({
    url: '/public/conf/getAllPost',
    method: 'get',
  });
}

// 公共-机构信息树形结构
export function getOrgTree() {
  return request({
    url: '/manage/select/orgtree',
    method: 'get',
  });
}

// 所有发布的课程
export function getCourses() {
  return request({
    url: '/public/conf/publishcourse',
    method: 'get',
  });
}

// 考试中心列表
export function getExams() {
  return request({
    url: '/public/conf/publishexam',
    method: 'get',
  });
}

// 管理员信息
export function getUserInfo() {
  return request({
    url: '/manage/admin/manage/info',
    method: 'post',
  });
}

// 导航链接
export function getLinks() {
  return request({
    url: '/manage/admin/nav/url',
    method: 'get',
  });
}

// 后台导航
export function getMenu() {
  return request({
    url: '/manage/admin/menu',
    method: 'post',
  });
}

// 获取 OSS 信息
export function getOSSInfo(data) {
  return request({
    url: '/index/oss/get',
    method: 'post',
    data,
  });
}

// 获取App跳转链接
export function getAppJumpPage() {
  return request({
    url: '/admin/ActivityCenter/getAppJump',
    method: 'get',
  });
}

// 获取举报类型
export function getReportType() {
  return request({
    url: '/commonno/select/informtype',
    method: 'get',
  });
}

// 上传图片
export function getImageUrl(data) {
  return request({
    url: '/Admin/Overt/upload_pic.html',
    method: 'post',
    data,
  });
}

// VOD 视频上传
export function uploadVideo(data) {
  return requestUnlimited({
    url: '/admin/Overt/upload_video',
    method: 'post',
    data,
  });
}

// 上传文件: video, image, pdf
export function uploadFile(data) {
  return requestUnlimited({
    url: '/ask/app/upload',
    method: 'post',
    data,
  });
}
