import OnlineCommunity from '@/router/modules/onlineCommunity/OnlineCommunity';

const Index = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Index');

const scriptRouter = [
  {
    path: 'onlineCommunity',
    component: Index,
    children: [
      {path: '', redirect: 'quiz'},
      ...OnlineCommunity,
    ],
  },
];

export default scriptRouter;
