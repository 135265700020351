import {stringify} from 'qs';
import {baseUrl} from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import {downloadFileExtra} from '@/utils/JsDownload';


// 问题-列表
export function getQuizList(params) {
  return request({
    url: `/ask/admin/asks`,
    method: 'get',
    params,
  });
}

// 回答-回答列表-导出 excel
export function getQuizListDownload(params) {
  let url = `/ask/admin/asks`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}


// 问题-详情
export function getQuizMsg(id) {
  return request({
    url: `/ask/admin/asks/${id}`,
    method: 'get',
  });
}


// 问题-分配标签
export function setQuizTags(id, data) {
  return request({
    url: `/ask/admin/asks/${id}/labels/assign`,
    method: 'post',
    data,
  });
}

// 问题-审核通过
export function setQuizAuditing(id) {
  return request({
    url: `/ask/admin/asks/${id}/status/pass`,
    method: 'post',
  });
}

// 问题-重置审核
export function resetQuizAuditing(id) {
  return request({
    url: `/ask/admin/asks/${id}/status/reset`,
    method: 'post',
  });
}

// 问题-置顶
export function setQuizTop(id) {
  return request({
    url: `/ask/admin/asks/${id}/top`,
    method: 'post',
  });
}

// 问题-删除
export function deleteQuiz(id) {
  return request({
    url: `/ask/admin/asks/${id}`,
    method: 'delete',
  });
}
