/***
 * id: 选中唯一标志
 * title: 显示文字
 * name: 刷新页面时，用于匹配当前选中项，与路由配置中 name 字段值保持一致
 * path: 路由地址
 */

  // 问题列表
const quizSubPage = [
    {id: '1', title: '回答列表', name: 'OnlineAnswerList', path: '/index/onlineCommunity/answer'},
    {id: '2', title: '评论列表', name: 'OnlineCommentList', path: '/index/onlineCommunity/comment'},
    {id: '3', title: '回复列表', name: 'OnlineReplyList', path: '/index/onlineCommunity/reply'},
    {id: '4', title: '添加回答', name: 'OnlineAddAnswer', path: '/index/onlineCommunity/addAnswer'},
  ];

// 举报管理
const reportSubPage = [
  {id: '1', title: '举报详情', name: 'OnlineReportDetail', path: '/index/onlineCommunity/reportDetail'},
];

export {
  quizSubPage,
  reportSubPage,
};


/***
 * 红旗首页配置模块，左侧导航数据，供 Nav.vue 组件，及其它组件使用
 * id: Nav.vue 组件中 menu 所需要的 key
 * label: Nav.vue 组件中 menu 所需要的显示的名称
 * path: 路由地址
 * children: Nav.vue 中二级菜单，目前只有二级
 * childrenPath: 右侧子路由列表。导航到该数组所包含的路由时，左侧 Nav.vue 中选中菜单保持不变
 */


const OnlineCommunity = [
  {
    id: '1', label: '问答管理', path: '/', children: [
      {
        id: '1-1', label: '问题列表', path: '/index/onlineCommunity/quiz', childrenPath: [
          ...quizSubPage.map(i => i.path),
        ],
      },
      {
        id: '1-9', label: '回答列表', path: '/index/onlineCommunity/answerList', childrenPath: [],
      },
      {
        id: '1-2', label: '话题管理', path: '/index/onlineCommunity/topic', childrenPath: [],
      },
      {
        id: '1-3', label: '标签管理', path: '/index/onlineCommunity/tag', childrenPath: [],
      },
      {
        id: '1-4', label: '举报管理', path: '/index/onlineCommunity/report', childrenPath: [
          ...reportSubPage.map(i => i.path),
        ],
      },
      {
        id: '1-5', label: '官方身份管理', path: '/index/onlineCommunity/official', childrenPath: [],
      },
      {
        id: '1-6', label: '积分规则管理', path: '/index/onlineCommunity/accumulatedRule', childrenPath: [],
      },
      {
        id: '1-7', label: '封禁管理', path: '/index/onlineCommunity/ban', childrenPath: [],
      },
      {
        id: '1-8', label: '搜索文案设置', path: '/index/onlineCommunity/searchPlaceholder', childrenPath: [],
      },
    ],
  },
];

export default OnlineCommunity;
