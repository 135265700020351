import request from '@/utils/Request';

// 内训---列表（自建）
export function getSelfBuiltTrainingList(params) {
  return request({
    url: `/internaltraining/admin/conf/selfbuildList`,
    method: 'get',
    params,
  });
}

// 内训---列表（自建）
export function getSelfBuiltStudentList(params) {
  return request({
    url: `/internaltraining/admin/Student/TrainingStatistics`,
    method: 'get',
    params,
  });
}

// 内训---评分明细（自建）
export function getSelfBuildScoreDetail(params) {
  return request({
    url: `/internaltraining/admin/selfBuildScoreDetail`,
    method: 'get',
    params,
  });
}