<template>
  <div class="show-password show-left">
    <div><h1>重置密码</h1></div>
    <div class="login-card">
      <div><h3>手机号</h3></div>
      <div>
        <div>
          <a-input
            id="username"
            name="username"
            v-model="data.mobile"
            @change="onCheckMobile"
            size="default"
            placeholder="请输入手机号"
          />
        </div>
      </div>
      <div v-if="!rule.isMobileCheck" class="login-tip">
        <span>手机号格式不正确</span>
      </div>
    </div>
    <div class="login-card">
      <div><h3>验证码</h3></div>
      <div>
        <div class="login-verificationcode">
          <!-- <a-input size="default" placeholder="请输入验证码" /> -->

          <a-input
            id="verificationcode"
            name="verificationcode"
            v-model="data.verificationCode"
            @change="onCheckVerificationCode"
            placeholder="请输入验证码"
          >
            <template slot="addonAfter">
              <a-button
                class="login-verificationcode-time"
                v-if="verificationCodeTime > 0"
              >
                {{ verificationCodeTime }} s
              </a-button>
              <a-button
                @click="getVerificationCode"
                v-else
                :disabled="!isMobileCheck"
                >{{ verificationCode }}</a-button
              >
            </template>
          </a-input>
        </div>
      </div>
      <div v-if="!rule.isVerificationCodeCheck" class="login-tip">
        <span>验证码错误</span>
      </div>
    </div>
    <div class="login-card">
      <div>
        <div><h3>设定新密码</h3></div>
        <div>
          <a-input-password
            id="password"
            name="password"
            v-model="data.password"
            @change="onCheckPassWord"
            size="default"
            placeholder="密码不少于6位"
          />
        </div>
        <div v-if="!rule.isPasswordCheck" class="login-tip">
          <span>密码不能少于6位</span>
        </div>
      </div>
    </div>
    <div class="login-btn">
      <span class="left"> <a-button @click="onBack"> 返回 </a-button></span>
      <span class="right">
        <a-button type="primary" @click="onLogin" block>
          重置密码
        </a-button></span
      >
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import Common from '@/utils/Common';
import {
  smsForgetpassword,
  forgetpassword
} from '@/api/userConfig/userCheck.js';
import Loading from '@/components/Loading.vue';

export default {
  name: 'ShowPassword',
  components: {
    Loading
  },
  data() {
    return {
      data: {
        mobile: '',
        password: '',
        verificationCode: ''
      },
      rule: {
        isMobileCheck: true,
        isPasswordCheck: true,
        isVerificationCodeCheck: true
      },
      verificationCode: '获取验证码',
      verificationCodeTime: 0,
      verificationCodeInterval: undefined,
      isMobileCheck: true,
      loading: false
    };
  },
  computed: {
    idcard() {
      return this.$router.query.idcard;
    }
  },
  methods: {
    async getVerificationCode() {
      console.log(this.verificationCodeTime);
      if (!(this.verificationCodeTime <= 0)) {
        return;
      }
      if (!this.onCheckMobile()) {
        return;
      }
      this.loading = true;
      const data = await smsForgetpassword({
        phone: this.data.mobile
      }).finally(() => {
        this.loading = false;
      });
      console.log(data);
      if (!(data && data.error_code === 0)) {
        this.$message.error(data.message);
        return;
      }
      this.verificationCodeTime = 60;
      this.verificationCodeInterval = setInterval(() => {
        if (this.verificationCodeTime <= 0) {
          this.verificationCodeTime = 0;
          clearInterval(this.verificationCodeInterval);
        }
        console.log(this.verificationCodeTime);
        this.verificationCodeTime -= 1;
      }, 1000);
    },
    async onLogin() {
      if (!this.Check()) {
        return;
      }
      this.loading = true;
      const data = await forgetpassword({
        phone: this.data.mobile,
        password: this.data.password,
        phone_code: this.data.verificationCode
      }).finally(() => {
        this.loading = false;
      });
      if (!(data.error_code === 0)) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success('密码重置成功');
      setTimeout(() => {
        this.verificationCodeTime = 0;
        clearInterval(this.verificationCodeInterval);
        this.$emit('onBack');
      }, 500);
    },
    onCheckMobile() {
      this.rule.isMobileCheck = true;
      this.isMobileCheck = true;
      if (!Common.isMobile(this.data.mobile)) {
        this.rule.isMobileCheck = false;
        this.isMobileCheck = false;
      }
      return this.rule.isMobileCheck;
    },
    createdCheckMobile() {
      this.isMobileCheck = true;
      if (!Common.isMobile(this.data.mobile)) {
        this.isMobileCheck = false;
      }
    },
    onCheckVerificationCode() {
      this.rule.isVerificationCodeCheck = true;
      if (!Common.isVerificationCode(this.data.verificationCode)) {
        this.rule.isVerificationCodeCheck = false;
      }
      return this.rule.isVerificationCodeCheck;
    },
    onCheckPassWord() {
      this.rule.isPasswordCheck = true;
      if (!(this.data.password.length >= 6)) {
        this.rule.isPasswordCheck = false;
      }
      return this.rule.isPasswordCheck;
    },
    Check() {
      var result = true;
      if (!this.onCheckMobile()) {
        result = false;
      }
      if (!this.onCheckVerificationCode()) {
        result = false;
      }
      if (!this.onCheckPassWord()) {
        result = false;
      }
      return result;
    },
    onBack() {
      this.verificationCodeTime = 0;
      clearInterval(this.verificationCodeInterval);
      this.$emit('onBack');
    }
  },
  created() {
    this.createdCheckMobile();
  }
};
</script>


<style lang="less" scoped>
.show-password {
  width: 100%;
  height: 100%;
  div {
    margin: 0 auto;
    h1 {
      font-size: 25px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      text-align: center;
    }
    h3 {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #83868e;
      margin: 0;
      padding: 0 0 10px 0;
    }
    .login-input {
      height: 40px;
    }
    ::v-deep .ant-input {
      height: 40px;
      border: none;
    }
    ::v-deep .ant-input::placeholder {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dadde5;
    }
  }
  .login-card {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .login-verificationcode {
    ::v-deep .ant-input-group-addon {
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
    }
    ::v-deep .ant-input-group {
      padding: 0;
      margin: 0;
      border: 0;
      border: none;
    }
    ::v-deep button {
      border: 1px solid #f15952;
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
      background: #ffffff;
    }

    ::v-deep .ant-input::placeholder {
      padding: 0;
      margin: 0;
      border: 0;
      border: none;
    }
    ::v-deep .ant-input-group-addon {
      background: #ffffff;
    }
    .login-verificationcode-time {
      border-radius: 3px;
      background: rgba(255, 211, 208, 0.5);
      border: none;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff5a52;
    }
    button[disabled='disabled'] {
      color: #d9d9d9 !important;
      background-color: #f5f5f5 !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
  .login-btn {
    display: flex;
    align-items: center;
    height: 44px;
    margin: 33px 0 0 0;
    padding: 0;
    span {
      flex: 1;
      .btn-item1 {
        flex: 1;
      }
      ::v-deep button {
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 120px;
        display: inline-block;
        height: 44px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff5a52;
        border: 1px solid #ff5a52;
      }
      ::v-deep .ant-btn-primary {
        background: #ff5a52;
        color: #ffffff;
      }
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
    button[disabled='disabled'] {
      color: #d9d9d9 !important;
      background-color: #f5f5f5 !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
  .login-tip {
    margin: 5px 0 0 0;
    text-align: right;
    color: #ff5a52;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .btn-item1 {
    flex: 1;
    margin-top: 5px;
  }
}
</style>
