<template>
  <div class="ss-nav">
    <a-menu class="menu"
            v-model="currentKey"
            :open-keys.sync="keysOpened"
            mode="inline"
            @openChange="openNav"
            @click="selectItem">
      <template v-for="nav of navs">
        <a-sub-menu v-if="nav.children && nav.children.length" :key="nav.id">
          <span slot="title"><a-icon type="home"/><span>{{ nav.label }}</span></span>
          <template v-if="nav.children && nav.children.length">
            <a-menu-item v-for="item in nav.children" :key="item.id">{{ item.label }}</a-menu-item>
          </template>
        </a-sub-menu>
        <template v-else>
          <a-menu-item :key="nav.id">
            <a-icon type="home"/>
            <span>{{ nav.label }}</span>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>

export default {
  name: 'Nav',
  props: {
    navs: { type: Array, default: () => [] },
  },
  watch: {
    navs(list) {
      if (!list || !list.length) {
        return;
      }
      this.keysOpened = list.map(i => i.id);
      this.update(this.$route?.path || '');
    },
    '$route.path'() {
      this.update(this.$route?.path || '');
    },
  },
  data() {
    return {
      keysOpened: [''],
      currentKey: [''],
    };
  },
  methods: {
    openNav(openKeys) {
      this.keysOpened = openKeys;
    },
    update(path) {
      this.currentKey = [this.getIdByPath(path, [].concat(...this.navs)) + ''];
    },
    getIdByPath(path, navs) {
      for (let i = 0; i < navs.length; i++) {
        if (navs[i].children && navs[i].children.length) {
          navs.push(...navs[i].children);
        }
        if (navs[i].childrenPath && navs[i].childrenPath.length && navs[i].childrenPath.some(i => i.includes(path))) {
          return navs[i].id;
        }
        if (navs[i].path.includes(path)) {
          return navs[i].id;
        }
      }
      return '';
    },
    getPathById(id, navs) {
      for (let i = 0; i < navs.length; i++) {
        if (navs[i].children && navs[i].children.length) {
          navs.push(...navs[i].children);
        }
        if (navs[i].id === id) {
          return navs[i].path;
        }
      }
    },
    selectItem({ key, keyPath }) {
      // console.warn(this.currentKey, ' : ', key, keyPath)
      if (this.$route.path === this.getPathById(key, [].concat(...this.navs))) {
        return;
      }
      this.currentKey = [key];
      const path = this.getPath(keyPath.concat([]), this.navs);
      this.$emit('select', { key, keyPath, path });
    },
    getPath(keyPath, navs) {
      let idx = 0;
      const path = keyPath.pop();
      if (navs.some((n, i) => {
        if (n.id === path) {
          idx = i;
          return true;
        }
        return false;
      })) {
        if (keyPath.length > 0 && navs[idx].children && navs[idx].children.length > 0) {
          return this.getPath(keyPath, this.navs[idx].children);
        } else {
          return navs[idx].path;
        }
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ss-nav {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 5px);
  border-radius: 2px;
  background-color: $light01;

  &::-webkit-scrollbar {
    width: 6px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #EDEDED;
  }

  .menu {
    border-right-width: 0;
  }
}
</style>
