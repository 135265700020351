const Quiz = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Quiz');
const AnswerList = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/AnswerList');
const Topic = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Topic');
const Tag = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Tag');
const Report = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Report');
const Official = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Official');
const Answer = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Answer');
const Comment = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Comment');
const Reply = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Reply');
// const AddAnswer = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/AddAnswer');
const AddAnswer = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/AddAnswerTinyMceVue');
const ReportDetail = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/ReportDetail');
const AccumulatedRule = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/AccumulatedRule');
const Ban = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/Ban');
const SearchPlaceholder = () => import(/* webpackChunkName: "OnlineCommunity" */  '@views/onlineCommunity/SearchPlaceholder');

const OnlineCommunityRouter = [
  {
    name: 'OnlineQuiz',
    path: 'quiz',
    component: Quiz,
  },
  {
    name: 'OnlineAnswerList',
    path: 'answerList',
    component: AnswerList,
  },
  {
    name: 'OnlineTopic',
    path: 'topic',
    component: Topic,
  },
  {
    name: 'OnlineTag',
    path: 'tag',
    component: Tag,
  },
  {
    name: 'OnlineReport',
    path: 'report',
    component: Report,
  },
  {
    name: 'OnlineOfficial',
    path: 'official',
    component: Official,
  },
  {
    name: 'OnlineAnswer',
    path: 'answer',
    component: Answer,
  },
  {
    name: 'OnlineComment',
    path: 'comment',
    component: Comment,
  },
  {
    name: 'OnlineReply',
    path: 'reply',
    component: Reply,
  },
  {
    name: 'OnlineAddAnswer',
    path: 'addAnswer',
    component: AddAnswer,
  },
  {
    name: 'OnlineReportDetail',
    path: 'reportDetail',
    component: ReportDetail,
  },
  {
    name: 'OnlineAccumulatedRule',
    path: 'accumulatedRule',
    component: AccumulatedRule,
  },
  {
    name: 'OnlineBan',
    path: 'ban',
    component: Ban,
  },
  {
    name: 'SearchPlaceholder',
    path: 'searchPlaceholder',
    component: SearchPlaceholder,
  },
];

export default OnlineCommunityRouter;
