/***
 * 奔腾数据统计模块，左侧导航数据，供 Nav.vue 组件，及其它组件使用
 * id: Nav.vue 组件中 menu 所需要的 key
 * label: Nav.vue 组件中 menu 所需要的显示的名称
 * path: 路由地址
 * children: Nav.vue 中二级菜单，目前只有二级
 * childrenPath: 右侧子路由列表。导航到该数组所包含的路由时，左侧 Nav.vue 中选中菜单保持不变
 */

const prefix = '/index/data-statistics/';

const broadcastFlowPrefix = `${prefix}broadcast/flow/`;
const broadcastFlowDetailPrefix = `${prefix}broadcast/flow/detail/`;
const broadcastQueryPrefix = `${prefix}broadcast/query/`;

const DataStatisticsNavs = [
  {
    id: '1', label: '直播统计', path: '/', children: [
      {
        id: '1-1', label: '直播流量统计', path: `${broadcastFlowPrefix}overview`, childrenPath: [
          `${broadcastFlowDetailPrefix.substring(0, broadcastFlowDetailPrefix.length - 1)}`,
          `${broadcastFlowDetailPrefix}broadcast`,
          `${broadcastFlowDetailPrefix}user`,
        ],
      },
      {
        id: '1-2', label: '直播数据查询', path: `${broadcastQueryPrefix}overview`, childrenPath: [
          `${broadcastQueryPrefix}overview/watch`,
          `${broadcastQueryPrefix}overview/heat`,
          `${broadcastQueryPrefix}heat-detail`,
        ],
      },
    ],
  },
];

/***
 * 直播数据页面内 TabMenu 选项配置
 * id: 选中唯一标志
 * title: 显示文字
 * name: 刷新页面时，用于匹配当前选中项，与路由配置中 name 字段值保持一致
 * path: 路由地址
 */

const broadcastDetail = [
  {
    id: '1',
    title: '直播明细',
    path: `${broadcastFlowDetailPrefix}broadcast`,
  },
  {
    id: '2',
    title: '人员明细',
    path: `${broadcastFlowDetailPrefix}user`,
  },
];


export {
  broadcastDetail,
};

export default DataStatisticsNavs;
