import request from '@/utils/Request';

export * from './Quiz';
export * from './Answer';
export * from './Comment';
export * from './Tags';
export * from './Topics';
export * from './Ban';
export * from './AccumulatedRule';
export * from './SearchPlaceholder';


// 公共-用户信息
export function getUserInfo(id) {
  return request({
    url: `/ask/admin/userinfo/${id}`,
    method: 'get',
  });
}
