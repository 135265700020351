 
<template>
  <div class="loading-spin">
    <div class="loading"></div>
    <div class="loading-logo">
      <a-spin size="large"> </a-spin>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hongqi-loading'
};
</script>
<style lang="less" scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #f8fdff;
  opacity: 0.7;
  z-index: 99;
}
.loading-logo {
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  margin: 0 auto;
  z-index: 100;
  position: absolute;
}
</style>