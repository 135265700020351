<template>
  <div class="tab-detail-page" :class="{'full-page': titles && titles.length}">
    <CommonTitle v-if="titles && titles.length" :titles="titles"></CommonTitle>

    <div class="flex space-between pd-card">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <template v-for="item of menus">
          <a-menu-item v-if="!item.hidden" :key="item.id"
                       :disabled="!showMenus && item.disabled">
            {{ item.title }}
          </a-menu-item>
        </template>
      </a-menu>

      <slot name="extra"></slot>
    </div>

    <div v-if="titles && titles.length" class="flex pd-card">
      <router-view/>
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle';

export default {
  name: 'TabDetailPage',
  components: { CommonTitle },
  props: {
    titles: { type: Array, default: () => [] },
    showMenus: { type: Boolean, default: false },
    menus: {
      type: Array,
      default: () => [],
      validator: (menus) => menus && menus.every((menu) => ['id', 'title', 'path'].every((key) => !!menu[key])),
    },
    query: { type: Object, default: () => ({}) },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.executeSelection(this.$route.path);
      },
    },
  },
  data() {
    return {
      selectedMenu: this.menus[0]?.id ? [this.menus[0].id] : [],
    };
  },
  created() {
    this.executeSelection(this.$route.path);
  },
  methods: {
    executeSelection(path) {
      this.menus.forEach(menu => {
        if (menu.path === path) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      const path = this.getPath(key);
      this.$router.replace({
        path,
        query: this.query,
      });
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => menu.id + '' === key + '' && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">

</style>
