<template>
  <div class="select-wrapper">
    <a-select v-model="selectedIds"
              :style="{ width: width }"
              ref="selectRef"
              :open="false"
              :allow-clear="false"
              :mode="'multiple'"
              :disabled="disabled"
              :show-arrow="false"
              :placeholder="'请选择' + placeholderText"
              class="select"
              @focus="showModal">
      <a-select-option :value="0">请选择组织机构</a-select-option>
      <a-select-option
        v-for="item in selectedData"
        :key="item.id"
        :value="item.id">
        {{ item.name }}
      </a-select-option>
    </a-select>

    <a-icon theme="filled"
            type="close-circle"
            class="clear-btn"
            :class="{ hidden: !selectedData || !selectedData.length }"
            @click="clear"/>

    <SelectOrgModal v-if="modalShown"
                    :data="parsedOptions"
                    :default-selected-ids="selectedIdsString"
                    :enabled-depth="enabledDepth"
                    :min="min"
                    :max="max"
                    :disable-merge-to-parent="disableMergeToParent"
                    @confirm="select"
                    @close="closeModal"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SelectOrgModal from '@components/select/SelectOrgModal';
import Common from '@/utils/Common';

export default {
  name: 'SelectOrg',
  components: { SelectOrgModal },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // value: { type: String, default: '' },
    value: { type: String },
    disabled: { type: Boolean, default: false },
    placeholderText: { type: String, default: '组织机构' },
    width: { type: String, default: '' },
    data: { type: Array },
    enabledDepth: { type: Array, default: () => ([]) },
    min: { type: Number, default: 0 }, // 最少允许选择几个组织机构
    max: { type: Number, default: 0 }, // 最多允许选择几个组织机构
    // 同一父级下的直接子元素，全部选中时，不合并至选中该父级元素
    disableMergeToParent: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      options: (state) => state.Common.orgTree,
    }),
    selectedIdsString() {
      return (this.selectedIds || []).join(',');
    },
    parsedOptions() {
      return this.data || this.options;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
    selectedIds: {
      handler(value) {
        this.$emit('change', value.toString());
        this.$emit('select', event);
      },
    },
  },
  data() {
    return {
      modalShown: false,
      selectedData: [],
      selectedIds: [],
    };
  },
  methods: {
    setDefault() {
      if (!this.value) {
        this.selectedIds = [];
        return;
      }

      this.selectedIds = this.value.split(',').map(
        (i) => +i,
      ).filter(
        (i) => !!i,
      );
      this.selectedData = Common.getSelectedItems(
        this.selectedIds,
        this.parsedOptions,
        { id: 'id', name: 'name' },
      );
    },
    showModal() {
      if (this.disabled) {
        return;
      }
      this.modalShown = true;
    },
    closeModal() {
      this.modalShown = false;
    },

    clear() {
      this.select({ data: [] });
    },

    select(event) {
      this.selectedData = event?.data || [];
      this.selectedIds = this.selectedData.map((i) => i.id);

      const ids = this.selectedIds.join(',');

      this.closeModal();
      this.$emit('change', ids);
      this.$emit('select', event);
    },
  },
};
</script>

<style scoped lang="scss">
.select-wrapper {
  position: relative;
}

.select {
  min-width: 350px;
  height: 32px;
}

.select:hover + .clear-btn:not(.hidden) {
  display: inline-block;
}

.clear-btn {
  position: absolute;
  top: 14px;
  right: 11px;
  z-index: 1;
  display: none;
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);

  &:not(.hidden):hover {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
}
</style>
