import axios from 'axios';
import {baseUrl} from '@/Config';
import store from '@/store/index';
import {removeToken} from '@/utils/Token';

import {message} from 'ant-design-vue';

const service = axios.create({
  baseURL: baseUrl,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000,
});

service.interceptors.request.use(
  config => {
    const token = store.state.User.token || '';
    config.headers['Accept'] = 'application/json';
    config.headers['X-Auth-Token'] = token;
    return config;
  },
  error => {
    console.warn('request error: ', error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204) {
      return Promise.resolve(Object.assign({_status_code: response.status}, response.data));
    }
    message.error({
      content: response.message || '服务器错误',
      duration: 1.8
    });
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    return Promise.reject(new Error(response.message || 'Error'));
  },
  error => {
    console.warn('response error: ', error);
    if (error.response.status === 401) {
      removeToken();
      const local = window.location.origin;
      window.location.href = store.state.User.logOutUrl || local;
    } else {
      message.error({
        content: error.response.data.message || '服务器端异常',
        duration: 1.8
      });
    }
    return Promise.reject(error);
  }
);

export default service;
