const certLevelList = () => import(/* webpackChunkName: "JobConfig" */ '@views/userConfig/certLevelConfig/List');
const certLevelOperation = () => import(/* webpackChunkName: "JobConfig" */ '@views/userConfig/certLevelConfig/OperationCertLevel');

const mapRouter = [
  {
    name: 'CertLevelList',
    path: 'certList',
    component: certLevelList,
  },
  {
    name: 'CertLevelOperation',
    path: 'certOperation',
    component: certLevelOperation,
  }
];

export default mapRouter;
