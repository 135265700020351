import request from '@/utils/Request';


// 社区--积分--积分规则
export function getAccumulatedRuleList() {
  return request({
    url: `/ask/admin/integral/getIntergraRuleList`,
    method: 'get',
  });
}

// 社区--积分--修改
export function setAccumulatedRule(id, data) {
  return request({
    url: `/ask/admin/integral/editIntergraRule/${id}`,
    method: 'post',
    data,
  });
}
