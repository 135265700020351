/***
 * 红旗用户配置模块，左侧导航数据，供 Nav.vue 组件，及其它组件使用
 * id: Nav.vue 组件中 menu 所需要的 key
 * label: Nav.vue 组件中 menu 所需要的显示的名称
 * path: 路由地址
 * children: Nav.vue 中二级菜单，目前只有二级
 * childrenPath: 右侧子路由列表。导航到该数组所包含的路由时，左侧 Nav.vue 中选中菜单保持不变
 */


const LearnMapNavs = [
  {
    id: '1', label: '岗位管理', path: '/', children: [
      {
        id: '1-1', label: '认证等级管理', path: '/index/userConfig/certList', childrenPath: [
          '/index/userConfig/certOperation',
        ],
      },
    ],
  },
];

/***
 * id: 选中唯一标志
 * title: 显示文字
 * name: 刷新页面时，用于匹配当前选中项，与路由配置中 name 字段值保持一致
 * path: 路由地址
 */

const listSubPage = [
  {id: '1', title: '认证等级编辑', name: 'CertOperation', path: '/index/userConfig/certOperation'},
];

export {
  listSubPage,
};

export default LearnMapNavs;
