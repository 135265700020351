const Index = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/Index');

const TrainingUnifiedList = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/List');

const TrainingUnifiedDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/unified/Detail');
const TrainingUnifiedBasic = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/unified/Basic');
const TrainingUnifiedContent = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/unified/Content');
const TrainingUnifiedMaterial = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/unified/Material');
const TrainingUnifiedConfirm = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/unified/Confirm');
const TrainingUnifiedScience = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/unified/Science');

const TrainingIndependentDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/independent/Detail');
const TrainingIndependentBasic = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/independent/Basic');
const TrainingIndependentContent = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/independent/Content');
const TrainingIndependentMaterial = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/independent/Material');
const TrainingIndependentConfirm = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/independent/Confirm');
const TrainingIndependentScience = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/independent/Science');

const TrainingUnifiedOrgIndex = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/Index');
const TrainingUnifiedOrgDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/Detail');
const TrainingUnifiedOrgBasic = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/Basic');
const TrainingUnifiedOrgProgress = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/Progress');
const TrainingUnifiedOrgMaterial = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/Material');

const TrainingUnifiedOrgDetailSubmittedMaterial = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/SubmittedMaterial');
const TrainingUnifiedOrgDetailStudentDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/StudentDetail');
const TrainingUnifiedOrgDetailScoreList = () => import(/* webpackChunkName: "Training" */  '@views/training/unified/detail/ScoreList');

const ScoreIndex = () => import(/* webpackChunkName: "Training" */  '@views/training/score/Index');
const ScoreOrg = () => import(/* webpackChunkName: "Training" */  '@views/training/score/ScoreOrgList');
const ScoreTraining = () => import(/* webpackChunkName: "Training" */  '@views/training/score/ScoreTrainingList');
const ScoreDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/score/ScoreDetail');

const Cloud = () => import(/* webpackChunkName: "Training" */  '@views/training/cloud/Cloud');
const CloudList = () => import(/* webpackChunkName: "Training" */  '@views/training/cloud/List');
const CloudVerify = () => import(/* webpackChunkName: "Training" */  '@views/training/cloud/Verify');
const ScoreRule = () => import(/* webpackChunkName: "Training" */  '@views/training/scoreRule/ScoreRule');
const ScoreRuleSelfBuilt = () => import(/* webpackChunkName: "Training" */  '@views/training/scoreRule/SelfBuilt');
const ScoreRuleUnified = () => import(/* webpackChunkName: "Training" */  '@views/training/scoreRule/Unified');
const ScoreRuleUnifiedAdd = () => import(/* webpackChunkName: "Training" */  '@views/training/scoreRule/UnifiendAdd');
const ScoreRuleUnifiedLabel = () => import(/* webpackChunkName: "Training" */  '@views/training/scoreRule/UnifiendLabel');
const PostConfig = () => import(/* webpackChunkName: "Training" */  '@views/training/postConfig/PostConfig');
const PostConfigDeploy = () => import(/* webpackChunkName: "Training" */  '@views/training/postConfig/Deploy');
const PostConfigVerify = () => import(/* webpackChunkName: "Training" */  '@views/training/postConfig/Verify');
const Survey = () => import(/* webpackChunkName: "Training" */  '@views/training/survey/Survey');
const SurveyList = () => import(/* webpackChunkName: "Training" */  '@views/training/survey/List');
const SurveyDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/survey/Detail');
const Form = () => import(/* webpackChunkName: "Training" */  '@views/training/form/Form');
const FormList = () => import(/* webpackChunkName: "Training" */  '@views/training/form/List');
const FormDetail = () => import(/* webpackChunkName: "Training" */  '@views/training/form/Detail');


const TrainingRouter = [
  {
    path: 'unified',
    component: Index,
    children: [
      { path: '', redirect: 'list' },
      {
        name: 'TrainingUnifiedList',
        path: 'list',
        component: TrainingUnifiedList,
      },
      {
        name: 'TrainingUnifiedOrgDetail',
        path: 'detail',
        component: TrainingUnifiedOrgIndex,
        children: [
          { path: '', redirect: 'index' },
          {
            path: 'index',
            component: TrainingUnifiedOrgDetail,
            children: [
              { path: '', redirect: 'basic' },
              { path: 'basic', component: TrainingUnifiedOrgBasic },
              { path: 'progress', component: TrainingUnifiedOrgProgress },
              { path: 'material', component: TrainingUnifiedOrgMaterial },
            ],
          },
          {
            path: 'material',
            component: TrainingUnifiedOrgDetailSubmittedMaterial,
          },
          {
            path: 'student',
            component: TrainingUnifiedOrgDetailStudentDetail,
          },
          {
            path: 'score',
            component: TrainingUnifiedOrgDetailScoreList,
          },
        ],
      },
      {
        path: 'unified',
        component: TrainingUnifiedDetail,
        children: [
          { path: '', redirect: 'basic' },
          { path: 'basic', component: TrainingUnifiedBasic },
          { path: 'content', component: TrainingUnifiedContent },
          { path: 'material', component: TrainingUnifiedMaterial },
          { path: 'confirm', component: TrainingUnifiedConfirm },
          { path: 'science', component: TrainingUnifiedScience },
        ],
      },
      {
        path: 'independent',
        component: TrainingIndependentDetail,
        children: [
          { path: '', redirect: 'basic' },
          { path: 'basic', component: TrainingIndependentBasic },
          { path: 'content', component: TrainingIndependentContent },
          { path: 'material', component: TrainingIndependentMaterial },
          { path: 'confirm', component: TrainingIndependentConfirm },
          { path: 'science', component: TrainingIndependentScience },

        ],
      },
    ],
  },
  {
    path: 'score',
    component: ScoreIndex,
    children: [
      { path: '', redirect: 'overview' },
      { path: 'overview', component: ScoreOrg },
      { path: 'training', component: ScoreTraining },
      { path: 'detail', component: ScoreDetail },
    ],
  },
  {
    path: 'cloud',
    component: Cloud,
    children: [
      { path: '', redirect: 'list' },
      {
        name: 'CloudList',
        path: 'list',
        component: CloudList,
      },
      {
        name: 'CloudVerify',
        path: 'verify',
        component: CloudVerify,
      },
    ],
  },
  {
    path: 'scoreRule',
    component: ScoreRule,
    children: [
      { path: '', redirect: 'unified' },
      {
        name: 'ScoreRuleUnified',
        path: 'unified',
        component: ScoreRuleUnified,
        children: [
          { path: '', redirect: 'add' },
          {
            name: 'ScoreRuleUnified',
            path: 'add',
            component: ScoreRuleUnifiedAdd,
          },
          {
            name: 'ScoreRuleUnified',
            path: 'label',
            component: ScoreRuleUnifiedLabel,
          }],
      },
      {
        name: 'ScoreRuleSelfBuilt',
        path: 'selfbuilt',
        component: ScoreRuleSelfBuilt,
      },
    ],
  },
  {
    path: 'post',
    component: PostConfig,
    children: [
      { path: '', redirect: 'unified' },
      {
        name: 'PostConfigDeploy',
        path: 'deploy',
        component: PostConfigDeploy,
      },
      {
        name: 'PostConfigVerify',
        path: 'verify',
        component: PostConfigVerify,
      },
    ],
  },
  {
    path: 'survey',
    component: Survey,
    children: [
      { path: '', redirect: 'list' },
      {
        name: 'SurveyList',
        path: 'list',
        component: SurveyList,
      },
      {
        name: 'SurveyDetail',
        path: 'detail',
        component: SurveyDetail,
      },
    ],
  },
  {
    path: 'form',
    component: Form,
    children: [
      { path: '', redirect: 'list' },
      {
        name: 'FormList',
        path: 'list',
        component: FormList,
      },
      {
        name: 'FormDetail',
        path: 'detail',
        component: FormDetail,
      },
    ],
  },

];

export default TrainingRouter;
