<template>
  <a-collapse default-active-key="1" :bordered="false">
    <template #expandIcon="props">
      <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0"/>
    </template>
    <a-collapse-panel key="1" :header="trainingTitle" :style="customStyle">
      <p>内训主题：{{ trainingTitle }}</p>
      <p>内训类型：{{ trainingType }}</p>
      <p class="mb-0">内训时间：{{ trainingDate }}</p>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import {
  getUnifiedTrainingBasic,
} from '@/api/training/Index';

export default {
  name: 'CollapseTrainingInfo',
  props: {
    id: { type: [String, Number], default: '' },
  },
  watch: {
    id(val, oldVal) {
      if (val !== oldVal) {
        this.getUnifiedTrainingBasic();
      }
    },
  },
  data() {
    return {
      trainingTitle: '',
      trainingType: '',
      trainingDate: '',
      customStyle: 'background: #f7f7f7; border-radius: 4px; margin-bottom: 5px; border: 0; overflow: hidden',
    };
  },
  created() {
    this.getUnifiedTrainingBasic();
  },
  methods: {
    async getUnifiedTrainingBasic() {
      if (!this.id) {
        return;
      }
      const data = await getUnifiedTrainingBasic(this.id);
      if (!data?.error_code) {
        const result = data?.data || {};
        this.trainingTitle = result.title || '';
        this.trainingType = result.type_title || '';
        this.trainingDate = `${result.starttime_1} 至 ${result.endtime_1}`;

        this.$emit('get-training-type', result.type);
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
