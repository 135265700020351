import Vue from 'vue';

Vue.prototype.$redirect = (path) => {
  if (/^http(s?):\/\//g.test(path)) {
    window.location.href = path;
    return;
  }
  const origin = window.location.origin;
  path = path || '';
  path = path.indexOf('/') === 0 ? path : ('/' + path);
  window.location.href = origin + path;
};

Vue.prototype.$open = (path) => {
  if (/^http(s?):\/\//g.test(path)) {
    window.open(path, '_blank');
    return;
  }
  const origin = window.location.origin;
  path = path || '';
  path = path.indexOf('/') === 0 ? path : ('/' + path);
  window.open(origin + path, '_blank');
};
