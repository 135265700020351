import acceptanceRoutes from './acceptance';

const Index = () => import(/* webpackChunkName: "Preparation" */  '@/views/preparation/Index');

const routes = [
  {
    path: 'preparation',
    component: Index,
    children: [
      { path: '', redirect: 'acceptance' },

      ...acceptanceRoutes,
    ],
  },
];

export default routes;
