import request from '@/utils/Request';

// 标签--分类列表
export function getTagList() {
  return request({
    url: `/ask/admin/label/getLabelList`,
    method: 'post',
  });
}

// 标签--分类--选择
export function getTagListForSelect() {
  return request({
    url: `/ask/admin/Label/getAskLabel`,
    method: 'post',
  });
}

// 标签--添加/修改 标签
export function saveTagDetail(data) {
  return request({
    url: `/ask/admin/label/adjustLabel`,
    method: 'post',
    data,
  });
}

// 标签--删除标签
export function deleteTag(id, data) {
  return request({
    url: `/ask/admin/label/deleteLabel/${id}`,
    method: 'post',
    data,
  });
}

