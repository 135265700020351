import { getBroadcastLiveList } from '@/api/data-statistics/Index';

const Broadcast = {
  namespaced: true,
  state: {
    chartRange: [],
    tableRange: [],
    liveIds: [],
    liveCourseName: '',
    userName: '',

    liveList: [],
    watchDataQuery: {
      live_id: '',
      post_id: '',
      org_id: '',
    },
  },
  mutations: {
    updateChartRange(state, range) {
      state.chartRange = range;
    },
    updateTableRange(state, range) {
      state.tableRange = range;
    },
    updateLiveIds(state, liveIds) {
      state.liveIds = liveIds;
    },
    updateLiveCourseName(state, name) {
      state.liveCourseName = name || '';
    },
    updateUserName(state, name) {
      state.userName = name;
    },

    updateLiveList(state, list) {
      state.liveList = list || [];
    },
    setWatchDataQuery(state, query) {
      state.watchDataQuery = { ...query };
    },
    resetWatchDataQuery(state) {
      state.watchDataQuery = {
        live_id: '',
        post_id: '',
        org_id: '',
      };
    },
  },
  actions: {
    async getBroadcastLiveList({ commit }) {
      const data = await getBroadcastLiveList({ page: 1, per_page: 9999999 });
      if (data && !data.error_code) {
        commit('updateLiveList', data?.data);
      }
    },
  },
};

export default Broadcast;
