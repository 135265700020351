const TrainingStore = {
  namespaced: true,
  state: {
    unifiedDetailOrgIds: '',
    scoreMonth: [],
  },
  mutations: {
    updateUnifiedDetailOrgIds(state, ids) {
      state.unifiedDetailOrgIds = ids || '';
    },
    updateScoreMonth(state, months) {
      state.scoreMonth = months;
    },
  },
  actions: {},
};

export default TrainingStore;
