import request from '@/utils/Request';

// 晋升地图 - 地图列表
export function getMapList(params) {
  return request({
    url: '/studymap/admin',
    method: 'get',
    params,
  });
}

// 晋升地图 - 岗位等级
export function getJobLevelList(params) {
  return request({
    url: '/postauth/admin/postall',
    method: 'get',
    params,
  });
}

// 晋升地图 - 上下架地图
export function pullOnOff({ id }) {
  return request({
    url: `/studymap/admin/status/${ id }`,
    method: 'post',
  });
}

// 晋升地图 - 删除地图信息
export function doDellearnMap(id) {
  const url = `/studymap/admin/${ id }`;
  return request({
    url,
    method: 'delete',
  });
}

// 晋升地图 - 修改地图基础信息
export function editMap(id, data) {
  return request({
    url: `/studymap/admin/${ id }`,
    method: 'post',
    data,
  });
}

// 晋升地图 - 添加地图基础信息
export function createMap(data) {
  return request({
    url: `/studymap/admin/create`,
    method: 'post',
    data,
  });
}

// 晋升地图 - 获取地图基础信息
export function getMap({ id }) {
  return request({
    url: `/studymap/admin/${ id }`,
    method: 'get',
  });
}

// 晋升地图 - 可选前置地图
export function getBeforeMapList(params) {
  return request({
    url: `/studymap/admin/beforemap`,
    method: 'get',
    params,
  });
}

// 晋升地图 - 关卡列表
export function getLevelList({ id }) {
  return request({
    url: `/studymap/admin/maplevel/${ id }`,
    method: 'get',
  });
}

// 晋升地图 - 解锁，完成条件类型
export function getFinishType(params) {
  return request({
    url: `/studymap/admin/type`,
    method: 'get',
    params,
  });
}

// 晋升地图 - 添加关卡
export function createLevel(data) {
  return request({
    url: `/studymap/admin/level/create`,
    method: 'post',
    data,
  });
}

// 晋升地图 - 修改关卡
export function editLevel(data) {
  const id = data.id;
  delete data.id;
  return request({
    url: `/studymap/admin/level/${ id }`,
    method: 'post',
    data,
  });
}

// 晋升地图 - 删除关卡
export function deleteLevel({ id }) {
  return request({
    url: `/studymap/admin/level/${ id }`,
    method: 'delete',
  });
}

// 晋升地图 - 关卡详细
export function getLevelDetail({ id }) {
  return request({
    url: `/studymap/admin/level/${ id }`,
    method: 'get',
  });
}

// 晋升地图 - 关卡条件
export function getLevelContent(params) {
  return request({
    url: `/studymap/admin/content`,
    method: 'get',
    params,
  });
}

// 晋升地图 - 地图配置校验
export function checkMap({ id }) {
  return request({
    url: `/studymap/admin/checkData/${ id }`,
    method: 'get',
  });
}

// 晋升地图 - 地图统计数据
export function getMapStatistic({ id }) {
  return request({
    url: `/studymap/admin/userLoglist/${ id }`,
    method: 'get',
  });
}
