import Map from '@/router/modules/learnMap/Map';

const Index = () => import(/* webpackChunkName: "LearnMap" */  '@views/learnMap/Index');

const scriptRouter = [
  {
    path: 'learnMap',
    component: Index,
    children: [
      {path: '', redirect: 'list'},
      ...Map,
    ],
  },
];

export default scriptRouter;
