import request from '@/utils/Request';


// 社区--封禁--列表
export function getBanList(params) {
  return request({
    url: `/ask/admin/AskBan/getBanList`,
    method: 'get',
    params,
  });
}

// 社区--封禁--添加
export function addUserBan(data) {
  return request({
    url: `/ask/admin/AskBan/addUserBan`,
    method: 'post',
    data,
  });
}

// 社区--封禁--解封
export function removeBanUser(data) {
  return request({
    url: `/ask/admin/AskBan/removeBanUser`,
    method: 'post',
    data,
  });
}
