import UnifiedRouter from '@/router/modules/training/Unified';
import SelfBuiltRouter from '@/router/modules/training/SelfBuilt';

const Index = () => import(/* webpackChunkName: "Training" */  '@views/training/Index');

const Router = [
  {
    path: 'training',
    component: Index,
    children: [
      { path: '', redirect: 'unified' },
      ...UnifiedRouter,
      ...SelfBuiltRouter,
    ],
  },
];

export default Router;
