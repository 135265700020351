import { menus as UnifiedMenus } from '@/views/training/unified/unified/Detail.vue';
import { menus as IndependentMenus } from '@/views/training/unified/independent/Detail.vue';
import { menus as OrgMenus } from '@/views/training/unified/detail/Detail.vue';

const TrainingNavs = [
  {
    id: '1', label: '内训列表', path: '/index/training/unified/list', childrenPath: [
      ...UnifiedMenus.map((i) => i.path),
      ...IndependentMenus.map((i) => i.path),
      ...OrgMenus.map((i) => i.path),
      '/index/training/unified/detail/material',
      '/index/training/unified/detail/score',
      '/index/training/unified/detail/student',
      '/index/training/unified/score-org',
      '/index/training/unified/score-training',
      '/index/training/unified/score-detail',
    ],
  },
  {
    id: '2', label: '自建内训列表', path: '/index/training/selfBuilt/list', childrenPath: [
      '/index/training/selfBuilt/student',
      '/index/training/selfBuilt/score',
    ],
  },
  {
    id: '3', label: '评分查询', path: '/index/training/score/overview', childrenPath: [
      '/index/training/score/overview',
      '/index/training/score/training',
      '/index/training/score/detail',
    ],
  },
  {
    id: '4', label: '内训管理岗位设置', path: '/', children: [
      { id: '3-1', label: '配置岗位', path: '/index/training/post/deploy' },
      { id: '3-2', label: '审核岗位', path: '/index/training/post/verify' },
    ],
  },
  {
    id: '5', label: '评分规则管理', path: '/', children: [
      {
        id: '4-1', label: '内训计划配置', path: '/index/training/scoreRule/unified/'
        , children: [], childrenPath: [
          '/index/training/scoreRule/unified/add',
          '/index/training/scoreRule/unified/label',
        ],
      },
      { id: '4-2', label: '自发组织配置', path: '/index/training/scoreRule/selfBuilt' },
    ],
  },
  {
    id: '6', label: '调研管理', path: '/index/training/survey', children: [], childrenPath: [
      '/index/training/survey/list',
      '/index/training/survey/detail',
    ],
  },
  {
    id: '7', label: '内训表单管理', path: '/index/training/form', children: [], childrenPath: [
      '/index/training/form/list',
      '/index/training/form/Detail',
    ],
  },
  {
    id: '8', label: '公开云管理', path: '/', children: [
      { id: '7-1', label: '公开云列表', path: '/index/training/cloud/list' },
      { id: '7-2', label: '公开申请审核', path: '/index/training/cloud/verify' },
    ],
  },
];

export default TrainingNavs;
