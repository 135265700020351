import Entrance from '@/router/modules/indexConfig/Entrance';

const Index = () => import(/* webpackChunkName: "ActivityCenter" */  '@views/indexConfig/Index');

const scriptRouter = [
  {
    path: 'entrance',
    component: Index,
    children: [
      {path: '', redirect: 'list'},
      ...Entrance,
    ],
  },
];

export default scriptRouter;
