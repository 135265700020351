const IsProd = process.env.NODE_ENV === 'production';
const IsDev = process.env.NODE_ENV !== 'production';
const UserProxy = true;
const HostUrl = "https://eqx-uat.faw.cn/";

const BaseUrl = IsProd ? '' : (UserProxy ? '' : HostUrl);

module.exports = {
  label: 'hq',
  isProd: IsProd,
  isDev: IsDev,
  baseUrl: BaseUrl,
  hostUrl: HostUrl,
  userProxy: UserProxy,
  ossBaseUrl: 'https://hongqiproimg.wemark.tech/hongqi_admin/',
  tinymceOssBaseUrl: 'https://hongqiproimg.wemark.tech/hongqi_admin/tinymce/',
};
