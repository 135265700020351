<template>
  <div id="app" class="app-root">
    <a-config-provider :locale="locale">
      <router-view/>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  name: 'app',
  data() {
    return {
      locale: zhCN,
    };
  },
  // mounted() {
  //   // 引入友盟统计JS文件
  //   const script = document.createElement('script')
  //   script.src = 'https://s9.cnzz.com/z_stat.php?id=1279515126&web_id=1279515126'
  //   script.language = 'JavaScript'
  //   document.body.appendChild(script)
  // },
  // watch: {
  //   '$route' () {
  //     // 增加Vue路由友盟统计
  //     if (window._czc) {
  //       let location = window.location
  //       let contentUrl = location.pathname + location.hash
  //       let refererUrl = '/'
  //       window._czc.push(['_trackPageview', contentUrl, refererUrl])
  //     }
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.app-root {
  min-height: 100%;
  height: 100%;
  //min-width: 1200px;
  background-color: #F4F4F6;
}
</style>
