/***
 * 红旗活动中心模块，左侧导航数据，供 Nav.vue 组件，及其它组件使用
 * id: Nav.vue 组件中 menu 所需要的 key
 * label: Nav.vue 组件中 menu 所需要的显示的名称
 * path: 路由地址
 * children: Nav.vue 中二级菜单，目前只有二级
 * childrenPath: 右侧子路由列表。导航到该数组所包含的路由时，左侧 Nav.vue 中选中菜单保持不变
 */


const ActivityCenterNavs = [
  {
    id: '1', label: '活动管理', path: '/', children: [
      {
        id: '1-1', label: '活动列表', path: '/index/activities/list', childrenPath: [
          '/index/activities/detail',
          '/index/activities/sequence',
        ],
      },
      {
        id: '1-2', label: '红旗845正月大拜年', path: '/index/activities/happy-new-year/'
      },
    ],
  },
];

/***
 * id: 选中唯一标志
 * title: 显示文字
 * name: 刷新页面时，用于匹配当前选中项，与路由配置中 name 字段值保持一致
 * path: 路由地址
 */

const listSubPage = [
  { id: '1', title: '活动详情', name: 'ActivityDetail', path: '/index/activities/detail' },
  { id: '2', title: '活动列表排序', name: 'ActivitySequence', path: '/index/activities/sequence' },
];

export {
  listSubPage,
};

export default ActivityCenterNavs;
