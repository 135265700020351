import {stringify} from 'qs';
import {baseUrl} from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import {downloadFileExtra} from '@/utils/JsDownload';


// 回答-回答列表
export function getAnswerList(id, params) {
  return request({
    url: `/ask/admin/answer/list/${id}`,
    method: 'get',
    params,
  });
}

// 回答-回答列表-导出 excel
export function getAnswerListDownload(id, params) {
  let url = `/ask/admin/answer/list/${id}`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 回答-回答列表
export function getAnswerAllList(params) {
  return request({
    url: `/ask/admin/answer/list`,
    method: 'get',
    params,
  });
}

// 回答-回答列表-导出 excel
export function getAnswerAllListDownload(params) {
  let url = `/ask/admin/answer/list`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 回答-设置取消精华
export function setAnswerTop(id, data) {
  return request({
    url: `/ask/admin/answer/top/${id}`,
    method: 'post',
    data,
  });
}

// 回答-删除回答
export function deleteAnswer(id) {
  return request({
    url: `/ask/admin/answer/${id}`,
    method: 'delete',
  });
}

// 回答-添加答案
export function getAnswerDetail(id) {
  return request({
    url: `/ask/admin/answer/${id}`,
    method: 'get',
  });
}

// 回答-回答详细
export function saveAnswerDetail(data) {
  return request({
    url: `/ask/admin/answer/add`,
    method: 'post',
    data,
  });
}

// 回答-审核通过
export function setAnswerPass(id) {
  return request({
    url: `/ask/admin/answer/status/${id}/pass`,
    method: 'post',
  });
}

// 回答-批量审核操作
export function setBatchAnswerPass(data) {
  return request({
    url: `/ask/admin/answer/batchpass`,
    method: 'post',
    data
  });
}

// 回答-审核重置
export function resetAnswerPass(id) {
  return request({
    url: `/ask/admin/answer/status/${id}/reset`,
    method: 'post',
  });
}
