import request from '@/utils/Request';

// 话题-话题列表
export function getTopicList(params) {
  return request({
    url: `/ask/admin/topic/index`,
    method: 'get',
    params,
  });
}

// 话题-话题列表
export function getTopicListActivity(params) {
  return request({
    url: `/ask/admin/topic/getTopicStat`,
    method: 'get',
    params,
  });
}

// 公用-话题下拉框
export function getTopicListForSelect(params) {
  return request({
    url: `/ask/admin/public/topicselect`,
    method: 'get',
    params,
  });
}

// 话题-删除
export function deleteTopic(data) {
  return request({
    url: `/ask/admin/topic/delete`,
    method: 'post',
    data,
  });
}

// 话题-保存
export function saveTopicDetail(data) {
  return request({
    url: `/ask/admin/topic/save`,
    method: 'post',
    data,
  });
}

// 话题-详情
export function getTopicDetail(id, data) {
  return request({
    url: `/ask/admin/topic/${id}`,
    method: 'post',
    data,
  });
}
