import { getJobLevelList } from '@/api/learnMap/Index';

const learnMapStore = {
  namespaced: true,
  state: {
    readOnly: false,

    jobLevelList: [],
  },
  mutations: {
    updateReadOnly(state, readOnly) {
      state.readOnly = readOnly;
    },
    updateJobLevelList(state, list) {
      state.jobLevelList = list || [];
    },
  },
  actions: {
    async getJobLevelList({ commit }, options) {
      const data = await getJobLevelList(options || {});
      if (!data || data.error_code) {
        return {};
      }
      const result = data?.data || [];
      result.forEach((item) => {
        item.displayName = item.name;

        item.level?.length && item.level.forEach((i) => {
          i.displayName = i.levelName;
        });
      });
      commit('updateJobLevelList', result);
    },
  },
};

export default learnMapStore;
