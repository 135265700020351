import Vue from 'vue';

import ImageViewer from '../components/viewer/ImageViewer.vue';
import SelectBase from '../components/select/SelectBase.vue';
import SelectJob from '../components/select/SelectJob.vue';
import SelectJobGrouped from '../components/select/SelectJobGrouped.vue';
import SelectOrg from '../components/select/SelectOrg.vue';

Vue.component('app-image-viewer', ImageViewer);
Vue.component('app-select-base', SelectBase);
Vue.component('app-select-job', SelectJob);
Vue.component('app-select-job-grouped', SelectJobGrouped);
Vue.component('app-select-org', SelectOrg);
